/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import {
  Account,
  AccountRole,
  AccountRoleUpdateWrapper,
  BulkUpdateRoleAssignmentsRequest,
  UserDescriptor,
} from '@ws1c/intelligence-models';

export const helper = {
  getRequestForAssignUsers,
  getRequestForUnassignUsers,
};

/**
 * getRequestForAssignUsers
 * @export
 * @param {Account[]} accounts
 * @param {AccountRole} role
 * @returns {BulkUpdateRoleAssignmentsRequest}
 */
export function getRequestForAssignUsers(accounts: Account[], role: AccountRole): BulkUpdateRoleAssignmentsRequest {
  return new BulkUpdateRoleAssignmentsRequest({
    accountRoleUpdateWrappers: accounts.map((account: Account) => {
      const roleIds: string[] = account?.roles?.map((accountRole: AccountRole) => accountRole.roleId);
      if (!roleIds.includes(role?.roleId)) {
        roleIds.push(role?.roleId);
      }
      return new AccountRoleUpdateWrapper({
        roleIds,
        userDescriptor: new UserDescriptor({
          id: account?.id,
        }),
      });
    }),
  });
}

/**
 * getRequestForUnassignUsers
 * @export
 * @param {Account[]} accounts
 * @param {AccountRole} role
 * @returns {BulkUpdateRoleAssignmentsRequest}
 */
export function getRequestForUnassignUsers(accounts: Account[], role: AccountRole): BulkUpdateRoleAssignmentsRequest {
  return new BulkUpdateRoleAssignmentsRequest({
    accountRoleUpdateWrappers: accounts.map((account: Account) => {
      const roleIds: string[] = [];
      account?.roles
        ?.filter((accountRole: AccountRole) => accountRole?.roleId !== role.roleId)
        .forEach((accountRole: AccountRole) => roleIds.push(accountRole.roleId));
      return new AccountRoleUpdateWrapper({
        roleIds,
        userDescriptor: new UserDescriptor({
          id: account?.id,
        }),
      });
    }),
  });
}
