/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import {
  AdminGroup,
  AdminGroupListItem,
  AdminGroupsListResponse,
  AdminGroupsState,
  AdminManagementPagedResponse,
  AdminManagementState,
} from '@ws1c/admin-management/models';
import { selectAdminManagementState } from '@ws1c/admin-management/store/admin-management.selector';

/**
 * AdminGroupsSelectors
 * @export
 * @class AdminGroupsSelectors
 */
export class AdminGroupsSelectors {
  /**
   * Selects the Admin Groups state slice from Admin Management store
   * @static
   * @type {MemoizedSelector<AdminManagementState, AdminGroupsState>}
   * @memberof AdminGroupsSelectors
   */
  public static selectAdminGroupsState: MemoizedSelector<AdminManagementState, AdminGroupsState> = createSelector(
    selectAdminManagementState,
    (state: AdminManagementState) => state.adminGroups,
  );

  /**
   * adminGroupsListResponse
   * @static
   * @type {MemoizedSelector<AdminManagementState, AdminGroupsListResponse>}
   * @memberof AdminGroupsSelectors
   */
  public static adminGroupsListResponse: MemoizedSelector<AdminManagementState, AdminGroupsListResponse> = createSelector(
    AdminGroupsSelectors.selectAdminGroupsState,
    (state: AdminGroupsState) => state.adminGroupsListResponse,
  );

  /**
   * editedAdminGroup
   * @static
   * @type {MemoizedSelector<AdminManagementState, AdminGroupListItem>}
   * @memberof AdminGroupsSelectors
   */
  public static editedAdminGroup: MemoizedSelector<AdminManagementState, AdminGroupListItem> = createSelector(
    AdminGroupsSelectors.selectAdminGroupsState,
    (state: AdminGroupsState) => state.editedAdminGroup,
  );

  /**
   * isInvitingAdminGroups
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof AdminGroupsSelectors
   */
  public static isInvitingAdminGroups: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    AdminGroupsSelectors.selectAdminGroupsState,
    (state: AdminGroupsState) => state.isInvitingAdminGroups,
  );

  /**
   * isLoadingAdminGroupsList
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof AdminGroupsSelectors
   */
  public static isLoadingAdminGroupsList: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    AdminGroupsSelectors.selectAdminGroupsState,
    (state: AdminGroupsState) => state.isLoadingAdminGroupsList,
  );

  /**
   * isLoadingSearchResults
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof AdminGroupsSelectors
   */
  public static isLoadingSearchResults: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    AdminGroupsSelectors.selectAdminGroupsState,
    (state: AdminGroupsState) => state.isLoadingSearchResults,
  );

  /**
   * searchedAdminGroups
   * @static
   * @type {MemoizedSelector<AdminManagementState, AdminGroup[]>}
   * @memberof AdminGroupsSelectors
   */
  public static searchedAdminGroups: MemoizedSelector<AdminManagementState, AdminGroup[]> = createSelector(
    AdminGroupsSelectors.selectAdminGroupsState,
    (state: AdminGroupsState) => state.searchedAdminGroups,
  );

  /**
   * selectedAdminGroupListItem
   * @static
   * @type {MemoizedSelector<AdminManagementState, AdminGroupListItem>}
   * @memberof AdminGroupsSelectors
   */
  public static selectedAdminGroupListItem: MemoizedSelector<AdminManagementState, AdminGroupListItem> = createSelector(
    AdminGroupsSelectors.selectAdminGroupsState,
    (state: AdminGroupsState) => state.selectedAdminGroupListItem,
  );

  /**
   * groupsListResponseForGroup
   * @static
   * @type {MemoizedSelector<AdminManagementState, AdminGroupsListResponse>}
   * @memberof AdminGroupsSelectors
   */
  public static groupsListResponseForGroup: MemoizedSelector<AdminManagementState, AdminGroupsListResponse> = createSelector(
    AdminGroupsSelectors.selectAdminGroupsState,
    (state: AdminGroupsState) => state.groupsListResponseForGroup,
  );

  /**
   * isLoadingGroupsListForGroup
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof AdminGroupsSelectors
   */
  public static isLoadingGroupsListForGroup: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    AdminGroupsSelectors.selectAdminGroupsState,
    (state: AdminGroupsState) => state.isLoadingGroupsListForGroup,
  );

  /**
   * usersListResponseForGroup
   * @static
   * @type {MemoizedSelector<AdminManagementState, AdminManagementPagedResponse>}
   * @memberof AdminGroupsSelectors
   */
  public static usersListResponseForGroup: MemoizedSelector<AdminManagementState, AdminManagementPagedResponse> = createSelector(
    AdminGroupsSelectors.selectAdminGroupsState,
    (state: AdminGroupsState) => state.usersListResponseForGroup,
  );

  /**
   * isLoadingUsersListForGroup
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof AdminGroupsSelectors
   */
  public static isLoadingUsersListForGroup: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    AdminGroupsSelectors.selectAdminGroupsState,
    (state: AdminGroupsState) => state.isLoadingUsersListForGroup,
  );
}
