/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import {
  AdminGroup,
  AdminGroupListItem,
  AdminGroupsInviteRequest,
  AdminGroupsListResponse,
  AdminGroupsUsersListRequest,
  AdminManagementPagedResponse,
  IntegrationOrgGroup,
} from '@ws1c/admin-management/models';
import { AccountRole } from '@ws1c/intelligence-models';

/**
 * Admins Groups Actions
 *
 * @export
 * @class AdminGroupsActions
 */
export class AdminGroupsActions {
  public static readonly CATEGORY = '[ADMIN GROUPS]';

  public static readonly addAdminGroup = createAction(`${AdminGroupsActions.CATEGORY} ADD_ADMIN_GROUP`);
  public static readonly editAdminGroup = createAction(
    `${AdminGroupsActions.CATEGORY} EDIT_ADMIN_GROUP`,
    props<{ adminGroupListItem: AdminGroupListItem }>(),
  );

  public static goToAdminGroupsListPage = createAction(`${AdminGroupsActions.CATEGORY} GO_TO_ADMIN_GROUPS_LIST_PAGE`);

  public static readonly inviteAdminGroups = createAction(
    `${AdminGroupsActions.CATEGORY} INVITE_ADMIN_GROUPS`,
    props<{ adminGroupsInviteRequest: AdminGroupsInviteRequest }>(),
  );

  public static readonly inviteAdminGroupsSuccess = createAction(`${AdminGroupsActions.CATEGORY} INVITE_ADMIN_GROUPS_SUCCESS`);

  public static readonly inviteAdminGroupsFailure = createAction(`${AdminGroupsActions.CATEGORY} INVITE_ADMIN_GROUPS_FAILURE`);

  public static readonly showLoadingAdminGroupsList = createAction(`${AdminGroupsActions.CATEGORY} SHOW_LOADING_ADMIN_GROUPS_LIST`);

  public static readonly loadAdminGroupsList = createAction(
    `${AdminGroupsActions.CATEGORY} LOAD_ADMIN_GROUPS_LIST`,
    props<{ pagedRequest: PagedRequest }>(),
  );

  public static readonly loadAdminGroupsListSuccess = createAction(
    `${AdminGroupsActions.CATEGORY} LOAD_ADMIN_GROUPS_LIST_SUCCESS`,
    props<{
      adminGroupsListResponse: AdminGroupsListResponse;
      orgGroupList: IntegrationOrgGroup[];
      serviceRoleById: Map<string, AccountRole>;
    }>(),
  );

  public static readonly loadAdminGroupsListFailure = createAction(`${AdminGroupsActions.CATEGORY} LOAD_ADMIN_GROUPS_LIST_FAILURE`);

  public static readonly searchAdminGroups = createAction(
    `${AdminGroupsActions.CATEGORY} SEARCH_ADMIN_GROUPS`,
    props<{ searchTerm: string }>(),
  );

  public static readonly searchAdminGroupsSuccess = createAction(
    `${AdminGroupsActions.CATEGORY} SEARCH_ADMIN_GROUPS_SUCCESS`,
    props<{ searchedAdminGroups: AdminGroup[] }>(),
  );

  public static readonly searchAdminGroupsFailure = createAction(`${AdminGroupsActions.CATEGORY} SEARCH_ADMIN_GROUPS_FAILURE`);

  public static readonly setSelectedAdminGroupListItem = createAction(
    `${AdminGroupsActions.CATEGORY} SET_SELECTED_ADMIN_GROUP_LIST_ITEM`,
    props<{ selectedAdminGroupListItem: AdminGroupListItem }>(),
  );

  public static readonly removeAdminGroupRoles = createAction(
    `${AdminGroupsActions.CATEGORY} REMOVE_ADMIN_GROUP_ROLES`,
    props<{ adminGroupListItem: AdminGroupListItem }>(),
  );

  public static readonly updateAdminGroupRoles = createAction(
    `${AdminGroupsActions.CATEGORY} UPDATE_ADMIN_GROUP_ROLES`,
    props<{ adminGroupListItem: AdminGroupListItem }>(),
  );

  public static readonly loadUsersListForGroup = createAction(
    `${AdminGroupsActions.CATEGORY} LOAD_USERS_LIST_FOR_GROUP`,
    props<{
      usersForGroupRequest: AdminGroupsUsersListRequest;
      adminGroupListItem: AdminGroupListItem;
    }>(),
  );

  public static readonly loadUsersListForGroupSuccess = createAction(
    `${AdminGroupsActions.CATEGORY} LOAD_USERS_LIST_FOR_GROUP_SUCCESS`,
    props<{ usersListResponseForGroup: AdminManagementPagedResponse }>(),
  );

  public static readonly loadUsersListForGroupFailure = createAction(`${AdminGroupsActions.CATEGORY} LOAD_USERS_LIST_FOR_GROUP_FAILURE`);

  public static readonly loadGroupsListForGroup = createAction(
    `${AdminGroupsActions.CATEGORY} LOAD_GROUPS_LIST_FOR_GROUP`,
    props<{
      groupsForGroupRequest: AdminGroupsUsersListRequest;
      adminGroupListItem: AdminGroupListItem;
    }>(),
  );

  public static readonly loadGroupsListForGroupSuccess = createAction(
    `${AdminGroupsActions.CATEGORY} LOAD_GROUPS_LIST_FOR_GROUP_SUCCESS`,
    props<{ groupsListResponseForGroup: AdminGroupsListResponse }>(),
  );

  public static readonly loadGroupsListForGroupFailure = createAction(`${AdminGroupsActions.CATEGORY} LOAD_GROUPS_LIST_FOR_GROUP_FAILURE`);
}
