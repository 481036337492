/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AdminRoles } from '@ws1c/admin-management/models/admin-roles.model';
import { AdminUser } from './admin-user.model';

/**
 * AdminUserListItem
 * Represents AdminUserList Item
 *
 * @export
 * @class AdminUserListItem
 * @extends {AdminRoles}
 */
@Serializable
export class AdminUserListItem extends AdminRoles {
  @JsonProperty('csp_org_id')
  public cspOrgId: string = undefined;

  @JsonProperty('user')
  public user: AdminUser = undefined;

  /**
   * Initializes instance of AdminUserListItem
   * @param {Array<Partial<AdminUserListItem>>} args - object initialization arguments
   * @memberof AdminUserListItem
   */
  constructor(...args: Array<Partial<AdminUserListItem>>) {
    super();
    Object.assign(this, ...args);
  }

  /**
   * Getter for displayName
   * @type {string}
   * @memberof AdminUserListItem
   */
  public get displayName(): string {
    return this.user?.firstName + ' ' + this.user?.lastName;
  }

  /**
   * Getter for email
   * @type {string}
   * @memberof AdminUserListItem
   */
  public get email(): string {
    return this.user?.email;
  }
}
