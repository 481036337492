/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { DataGridComponent, PagedRequest, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap, startWith, switchMap, withLatestFrom } from 'rxjs/operators';

import { ScopeSetAccessOption } from '@ws1c/admin-management/const';
import { AdminManagementState, ExternalServiceRolesRequest, IntegrationOrgGroup, PlatformRole } from '@ws1c/admin-management/models';
import { RolesService } from '@ws1c/admin-management/services/roles.service';
import { getOrgGroupIdsByServiceType, getSelectedRoles } from '@ws1c/admin-management/utils';
import { I18NService } from '@ws1c/intelligence-common';
import {
  AccountService,
  AlertBannerActions,
  FeatureSelectors,
  IntegratedServicesSelectors,
  UserPreferenceSelectors,
} from '@ws1c/intelligence-core';
import {
  Account,
  AccountRole,
  AccountsSearchResponse,
  ALERT_BANNER_TYPE,
  BulkUpdateRoleAssignmentsRequest,
  CreateCustomRoleRequest,
  GenericSearchRequest,
  IntegratedServiceType,
  Integration,
  IntegrationDetails,
  IntegrationDetailsListByKey,
  RolesSearchResponse,
  ScopeSetSearchResponse,
} from '@ws1c/intelligence-models';
import { RolesActions } from './roles.actions';
import { helper } from './roles.effects.helpers';
import { RolesSelectors } from './roles.selectors';

/**
 * RolesEffects
 *
 * @export
 * @class RolesEffects
 */
@Injectable()
export class RolesEffects {
  /**
   * getExternalServiceRoles$
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public getExternalServiceRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.getExternalServiceRoles),
      withLatestFrom(this.store.select(RolesSelectors.selectedOrgGroupByIntegrationId)),
      filter(
        ([{ externalServiceRolesRequest }, selectedOrgGroupByIntegrationId]: [
          ReturnType<typeof RolesActions.getExternalServiceRoles>,
          Record<string, IntegrationOrgGroup>,
        ]) => {
          const { integrationDetails } = externalServiceRolesRequest;
          return integrationDetails.integration !== Integration.AIRWATCH || !!selectedOrgGroupByIntegrationId[integrationDetails.id];
        },
      ),
      switchMap(
        ([{ externalServiceRolesRequest }, selectedOrgGroupByIntegrationId]: [
          ReturnType<typeof RolesActions.getExternalServiceRoles>,
          Record<string, IntegrationOrgGroup>,
        ]) => {
          const integrationId: string = externalServiceRolesRequest?.integrationDetails?.id;
          return this.rolesService
            .getRolesByIntegrationId(externalServiceRolesRequest, selectedOrgGroupByIntegrationId[integrationId])
            .pipe(
              map((externalServiceRolesResponse: RolesSearchResponse) => {
                return RolesActions.getExternalServiceRolesSuccess({
                  integrationId,
                  externalServiceRolesResponse,
                });
              }),
              catchError((webError: WebError) => [
                RolesActions.getExternalServiceRolesFailure({ integrationId }),
                AlertBannerActions.showAlertBanner({
                  message: this.i18nService.translate('ADMIN_MGMT_COMMON_MESSAGES.GET_SERVICE_ROLES_FAILURE_MSG', {
                    reason: webError.getFullReason(),
                  }),
                  alertType: ALERT_BANNER_TYPE.DANGER,
                }),
              ]),
            );
        },
      ),
    ),
  );

  /**
   * getAllRolesForExternalServices$
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public getAllRolesForExternalServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.getAllRolesForExternalServices),
      withLatestFrom(
        this.store.select(UserPreferenceSelectors.gemIntegrationDetailsListByType),
        this.store.select(IntegratedServicesSelectors.allowedIntegratedServiceToIntegrationMap),
      ),
      mergeMap(
        ([_action, integrationDetailsListByKey, integratedServiceToIntegrationMap]: [
          ReturnType<typeof RolesActions.getAllRolesForExternalServices>,
          IntegrationDetailsListByKey,
          Partial<Record<IntegratedServiceType, Integration>>,
        ]) => {
          return Object.keys(integratedServiceToIntegrationMap).map((serviceType: IntegratedServiceType) => {
            const externalServiceRolesRequest: ExternalServiceRolesRequest = {
              integrationDetails: integrationDetailsListByKey[integratedServiceToIntegrationMap[serviceType] as string]?.[0],
              pagedRequest: new PagedRequest(),
            };
            return RolesActions.getAllRolesForExternalService({ externalServiceRolesRequest });
          });
        },
      ),
    ),
  );

  /**
   * getAllRolesForExternalService$
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public getAllRolesForExternalService$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.getAllRolesForExternalService),
      filter(({ externalServiceRolesRequest }: ReturnType<typeof RolesActions.getAllRolesForExternalService>) => {
        return (
          !!externalServiceRolesRequest?.integrationDetails &&
          // This condition is added because api supports WS1_UEM only, implementation need to be done for other integrations
          externalServiceRolesRequest?.integrationDetails.integration === Integration.AIRWATCH
        );
      }),
      mergeMap(({ externalServiceRolesRequest }: ReturnType<typeof RolesActions.getAllRolesForExternalService>) => {
        const integrationId: string = externalServiceRolesRequest?.integrationDetails?.id;
        return this.rolesService.getAllRolesByIntegrationId(externalServiceRolesRequest).pipe(
          map((externalServiceRolesResponse: RolesSearchResponse) => {
            return RolesActions.getExternalServiceRolesSuccess({
              integrationId,
              externalServiceRolesResponse,
            });
          }),
          catchError((webError: WebError) => [
            RolesActions.getExternalServiceRolesFailure({
              integrationId,
            }),
            AlertBannerActions.showAlertBanner({
              message: this.i18nService.translate('ADMIN_MGMT_COMMON_MESSAGES.GET_SERVICE_ROLES_FAILURE_MSG', {
                reason: webError.getFullReason(),
              }),
              alertType: ALERT_BANNER_TYPE.DANGER,
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * getIntelligenceRoles$
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public getIntelligenceRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.getIntelligenceRoles),
      startWith(RolesActions.getIntelligenceRoles),
      withLatestFrom(this.store.select(RolesSelectors.selectedIntelligenceRoles)),
      filter(([_action, selectedIntelligenceRoles]: [Action, AccountRole[]]) => {
        return !selectedIntelligenceRoles?.length;
      }),
      switchMap(() => {
        return this.accountService.getIamRoles().pipe(
          map((results: AccountRole[]) => {
            return RolesActions.getIntelligenceRolesSuccess({
              intelligenceRolesResponse: new RolesSearchResponse({
                from: 0,
                results,
                size: DataGridComponent.DEFAULT_PAGE_SIZE,
                total: results.length,
              }),
            });
          }),
          catchError((webError: WebError) => [
            RolesActions.getIntelligenceRolesFailure(),
            AlertBannerActions.showAlertBanner({
              message: this.i18nService.translate('ADMIN_MGMT_COMMON_MESSAGES.GET_SERVICE_ROLES_FAILURE_MSG', {
                reason: webError.getFullReason(),
              }),
              alertType: ALERT_BANNER_TYPE.DANGER,
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * searchIntelligenceRoles$
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public searchIntelligenceRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RolesActions.searchIntelligenceRoles,
        RolesActions.saveCustomRoleSuccess,
        RolesActions.changeRolesPagination,
        RolesActions.deleteCustomRoleSuccess,
        RolesActions.saveEditCustomRoleSuccess,
      ),
      withLatestFrom(this.store.select(RolesSelectors.getIntelligenceRolesSearchRequest)),
      switchMap(([_action, request]: [Action, GenericSearchRequest]) => {
        return this.rolesService.getRoles(request).pipe(
          map((response: RolesSearchResponse) => {
            return RolesActions.getIntelligenceRolesSuccess({
              intelligenceRolesResponse: response,
            });
          }),
          catchError((webError: WebError) => [
            RolesActions.getIntelligenceRolesFailure(),
            AlertBannerActions.showAlertBanner({
              message: this.i18nService.translate('ADMIN_MGMT_COMMON_MESSAGES.GET_SERVICE_ROLES_FAILURE_MSG', {
                reason: webError.getFullReason(),
              }),
              alertType: ALERT_BANNER_TYPE.DANGER,
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * saveCustomRole$
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public saveCustomRole$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.saveCustomRole),
      withLatestFrom(this.store.select(RolesSelectors.getScopeSetAccessById)),
      switchMap(
        ([{ name, description }, scopeSetAccessById]: [
          ReturnType<typeof RolesActions.saveCustomRole>,
          Record<string, ScopeSetAccessOption>,
        ]) => {
          const request: CreateCustomRoleRequest = new CreateCustomRoleRequest({
            name,
            description,
            scopeSetIds: Object.keys(scopeSetAccessById).reduce((acc: Record<string, boolean>, scopeSetId: string) => {
              if (scopeSetAccessById[scopeSetId] === ScopeSetAccessOption.NO_ACCESS) {
                return {
                  ...acc,
                };
              }
              return {
                ...acc,
                [scopeSetId]: scopeSetAccessById[scopeSetId] === ScopeSetAccessOption.READ_ONLY,
              };
            }, {}),
          });
          return this.rolesService.createCustomRole(request).pipe(
            switchMap(() => [
              AlertBannerActions.showAlertBanner({
                message: this.i18nService.translate('ROLES.CREATE_CUSTOM_ROLE_SUCCESS_MESSAGE', {
                  name,
                }),
                alertType: ALERT_BANNER_TYPE.SUCCESS,
              }),
              RolesActions.saveCustomRoleSuccess(),
            ]),
            catchError((error: WebError) => [
              RolesActions.saveCustomRoleFailure({ error }),
              AlertBannerActions.showAlertBanner({
                message: this.i18nService.translate('ROLES.CREATE_CUSTOM_ROLE_ERROR_MSG', {
                  reason: error.getFullReason(),
                }),
                alertType: ALERT_BANNER_TYPE.DANGER,
              }),
            ]),
          );
        },
      ),
    ),
  );

  /**
   * editCustomRole$
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public editCustomRole$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.saveEditCustomRole),
      withLatestFrom(this.store.select(RolesSelectors.getScopeSetAccessById)),
      switchMap(
        ([{ name, description, id }, scopeSetAccessById]: [
          ReturnType<typeof RolesActions.saveEditCustomRole>,
          Record<string, ScopeSetAccessOption>,
        ]) => {
          const scopeSetIds: Record<string, boolean> = Object.keys(scopeSetAccessById).reduce(
            (acc: Record<string, boolean>, scopeSetId: string) => {
              if (scopeSetAccessById[scopeSetId] === ScopeSetAccessOption.NO_ACCESS) {
                return {
                  ...acc,
                };
              }
              return {
                ...acc,
                [scopeSetId]: scopeSetAccessById[scopeSetId] === ScopeSetAccessOption.READ_ONLY,
              };
            },
            {},
          );
          const request: CreateCustomRoleRequest = new CreateCustomRoleRequest({
            name,
            description,
            id,
            scopeSetIds,
          });
          return this.rolesService.editCustomRole(request).pipe(
            switchMap(() => [
              AlertBannerActions.showAlertBanner({
                message: this.i18nService.translate('ROLES.EDIT_ROLE_SUCCESS_MSG', {
                  name,
                }),
                alertType: ALERT_BANNER_TYPE.SUCCESS,
              }),
              RolesActions.saveEditCustomRoleSuccess(),
            ]),
            catchError((error: WebError) => [
              RolesActions.saveEditCustomRoleFailure({ error }),
              AlertBannerActions.showAlertBanner({
                message: this.i18nService.translate('ROLES.EDIT_ROLE_ERROR_MSG', {
                  reason: error.getFullReason(),
                }),
                alertType: ALERT_BANNER_TYPE.DANGER,
              }),
            ]),
          );
        },
      ),
    ),
  );

  /**
   * deleteCustomRole
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public deleteCustomRole$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.confirmDeleteCustomRole),
      withLatestFrom(this.store.select(RolesSelectors.getBulkRoleIdsToDelete), this.store.select(RolesSelectors.getSelectedRole)),
      switchMap(([_action, identifiers, role]: [Action, string[], AccountRole]) => {
        return this.rolesService.deleteCustomRole(identifiers).pipe(
          switchMap(() => [
            AlertBannerActions.showAlertBanner({
              message: this.i18nService.translate('ROLES.DELETE_CUSTOM_ROLE_SUCCESS_MESSAGE', {
                name: role?.name,
              }),
              alertType: ALERT_BANNER_TYPE.SUCCESS,
            }),
            RolesActions.deleteCustomRoleSuccess(),
          ]),
          catchError((error: WebError) => [
            RolesActions.deleteCustomRoleFailure({ error }),
            AlertBannerActions.showAlertBanner({
              message: this.i18nService.translate('ROLES.DELETE_CUSTOM_ROLE_ERROR_MSG', {
                reason: error.getFullReason(),
              }),
              alertType: ALERT_BANNER_TYPE.DANGER,
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * unassignAccounts
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public unassignAccounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.confirmUnassignAccounts),
      withLatestFrom(this.store.select(RolesSelectors.getSelectedAccountsToUnassign), this.store.select(RolesSelectors.getSelectedRole)),
      switchMap(([_action, accounts, role]: [Action, Account[], AccountRole]) => {
        const request: BulkUpdateRoleAssignmentsRequest = helper.getRequestForUnassignUsers(accounts, role);
        return this.rolesService.assignAccounts(request).pipe(
          switchMap(() => [
            AlertBannerActions.showAlertBanner({
              message: this.i18nService.translate('ROLES.UNASSIGN_ACCOUNTS_SUCCESS_MESSAGE'),
              alertType: ALERT_BANNER_TYPE.SUCCESS,
            }),
            RolesActions.unassignAccountsSuccess({ roleId: role?.roleId }),
          ]),
          catchError((error: WebError) => [
            RolesActions.unassignAccountsFailure({ error }),
            AlertBannerActions.showAlertBanner({
              message: this.i18nService.translate('ROLES.UNASSIGN_ACCOUNTS_ERROR_MSG', {
                reason: error.getFullReason(),
              }),
              alertType: ALERT_BANNER_TYPE.DANGER,
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * assignUsers$
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public assignUsers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.confirmAssignUsers),
      withLatestFrom(this.store.select(RolesSelectors.getSelectedUserToAssign), this.store.select(RolesSelectors.getSelectedRole)),
      switchMap(([_action, accounts, role]: [Action, Account[], AccountRole]) => {
        const request: BulkUpdateRoleAssignmentsRequest = helper.getRequestForAssignUsers(accounts, role);
        return this.rolesService.assignAccounts(request).pipe(
          switchMap(() => [
            AlertBannerActions.showAlertBanner({
              message: this.i18nService.translate('ROLES.ASSIGN_USERS_SUCCESS_MESSAGE'),
              alertType: ALERT_BANNER_TYPE.SUCCESS,
            }),
            RolesActions.assignUsersSuccess({ roleId: role?.roleId }),
          ]),
          catchError((error: WebError) => [
            RolesActions.assignUsersFailure({ error }),
            AlertBannerActions.showAlertBanner({
              message: this.i18nService.translate('ROLES.ASSIGN_USERS_ERROR_MSG', {
                reason: error.getFullReason(),
              }),
              alertType: ALERT_BANNER_TYPE.DANGER,
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * getPlatformRoles$
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public getPlatformRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.getPlatformRoles),
      startWith(RolesActions.getPlatformRoles),
      withLatestFrom(this.store.select(UserPreferenceSelectors.isWSOneRbacEnabled), this.store.select(FeatureSelectors.hasGroupsUsersPerm)),
      filter(([_action, isWSOneRbacEnabled, hasGroupsUsersPerm]: [Action, boolean, boolean]) => isWSOneRbacEnabled && hasGroupsUsersPerm),
      switchMap(() => {
        return this.rolesService.getPlatformRoles().pipe(
          map((platformRoles: PlatformRole[]) => {
            return RolesActions.getPlatformRolesSuccess({
              platformRoles: platformRoles.filter((platformRole: PlatformRole) => !platformRole.isHidden),
            });
          }),
          catchError((webError: WebError) => [
            RolesActions.getPlatformRolesFailure(),
            AlertBannerActions.showAlertBanner({
              message: this.i18nService.translate('ADMIN_MGMT_COMMON_MESSAGES.GET_PLATFORM_ROLES_FAILURE_MSG', {
                reason: webError.getFullReason(),
              }),
              alertType: ALERT_BANNER_TYPE.DANGER,
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * getOrgGroupListByIntegrationId$
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public getOrgGroupListByIntegrationId$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.getOrgGroupListByIntegrationId),
      switchMap(({ integrationId }: ReturnType<typeof RolesActions.getOrgGroupListByIntegrationId>) => {
        return this.rolesService.getOrgGroupListByIntegrationId(integrationId).pipe(
          map((orgGroupList: IntegrationOrgGroup[]) => {
            return RolesActions.getOrgGroupListByIntegrationIdSuccess({
              integrationId,
              orgGroupList,
            });
          }),
          catchError((webError: WebError) => [
            RolesActions.getOrgGroupListByIntegrationIdFailure({ integrationId }),
            AlertBannerActions.showAlertBanner({
              message: this.i18nService.translate('ROLES.GET_ORG_GROUP_HIERARCHY_FAILURE_MSG', {
                reason: webError.getFullReason(),
              }),
              alertType: ALERT_BANNER_TYPE.DANGER,
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * setSelectedRoles$
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public setSelectedRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.setSelectedRoles),
      withLatestFrom(
        this.store.select(RolesSelectors.platformRoleByName),
        this.store.select(RolesSelectors.serviceRoleById),
        this.store.select(IntegratedServicesSelectors.allowedIntegratedServiceToIntegrationMap),
        this.store.select(UserPreferenceSelectors.gemIntegrationDetailsListByType),
      ),
      mergeMap(
        ([
          { platformRoles, serviceRoles },
          platformRoleByName,
          serviceRoleById,
          allowedIntegratedServiceToIntegrationMap,
          gemIntegrationDetailsListByType,
        ]: [
          ReturnType<typeof RolesActions.setSelectedRoles>,
          Map<string, PlatformRole>,
          Map<string, AccountRole>,
          Record<IntegratedServiceType, Integration>,
          IntegrationDetailsListByKey,
        ]) => {
          const { selectedPlatformRoles, selectedIntelligenceRoles, selectedExternalServiceRolesMap } = getSelectedRoles(
            platformRoles,
            serviceRoles,
            platformRoleByName,
            serviceRoleById,
            allowedIntegratedServiceToIntegrationMap,
            gemIntegrationDetailsListByType,
          );
          const ogIdsSet: Set<string> = getOrgGroupIdsByServiceType(serviceRoles)?.get(IntegratedServiceType.WS1_UEM);
          const actions: Action[] = [
            RolesActions.getPlatformRolesSuccess({ platformRoles: selectedPlatformRoles }),
            RolesActions.setSelectedIntelligenceRoles({ selectedIntelligenceRoles }),
            RolesActions.setSelectedRolesForAllExternalServices({ selectedExternalServiceRolesMap }),
          ];
          // Return early with default actions if ogIdsSet set is empty
          // This will happen only when select roles doesn't have UEM roles
          if (!ogIdsSet?.size) {
            return actions;
          }
          // Set selected org group if selected roles includes UEM service roles
          const selectedOrgGroup: IntegrationOrgGroup = new IntegrationOrgGroup({
            tenantUuid: [...ogIdsSet.values()][0],
          });
          return [
            RolesActions.setSelectedOrgGroupByIntegrationId({
              integrationId: gemIntegrationDetailsListByType[Integration.AIRWATCH]?.[0]?.id,
              selectedOrgGroup,
            }),
            ...actions,
          ];
        },
      ),
    ),
  );

  /**
   * getOrgGroupListForServiceTypes$
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public getOrgGroupListForServiceTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.getOrgGroupListForServiceTypes),
      withLatestFrom(
        this.store.select(IntegratedServicesSelectors.allowedIntegratedServiceToIntegrationMap),
        this.store.select(UserPreferenceSelectors.gemIntegrationDetailsListByType),
      ),
      mergeMap(
        ([action, integratedServiceToIntegrationMap, gemIntegrationDetailsListByType]: [
          ReturnType<typeof RolesActions.getOrgGroupListForServiceTypes>,
          Partial<Record<IntegratedServiceType, Integration>>,
          IntegrationDetailsListByKey,
        ]) => {
          return action.serviceTypes.map((serviceType: string) => {
            const integration: string = integratedServiceToIntegrationMap[serviceType] as string;
            const integrationDetails: IntegrationDetails = gemIntegrationDetailsListByType[integration]?.[0];
            return RolesActions.getOrgGroupListForServiceType({ integrationId: integrationDetails?.id });
          });
        },
      ),
    ),
  );

  /**
   * getOrgGroupListForServiceType$
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public getOrgGroupListForServiceType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.getOrgGroupListForServiceType),
      withLatestFrom(this.store.select(RolesSelectors.orgGroupsByIntegrationId)),
      filter(
        ([{ integrationId }, orgGroupListByIntegrationId]: [
          ReturnType<typeof RolesActions.getOrgGroupListForServiceType>,
          Record<string, IntegrationOrgGroup[]>,
        ]) => {
          return !orgGroupListByIntegrationId?.[integrationId];
        },
      ),
      switchMap(
        ([{ integrationId }]: [ReturnType<typeof RolesActions.getOrgGroupListForServiceType>, Record<string, IntegrationOrgGroup[]>]) => {
          return [RolesActions.getOrgGroupListByIntegrationId({ integrationId })];
        },
      ),
    ),
  );

  /**
   * getScopeSet$
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public getScopeSet$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.getScopeSet),
      withLatestFrom(this.store.select(RolesSelectors.getScopeSetSearchRequest)),
      switchMap(([_action, request]: [Action, GenericSearchRequest]) => {
        return this.rolesService.getScopeSet(request).pipe(
          map((response: ScopeSetSearchResponse) => RolesActions.getScopeSetSuccess({ response })),
          catchError((error: WebError) => [
            RolesActions.getScopeSetFailure({ error }),
            AlertBannerActions.showAlertBanner({
              message: this.i18nService.translate('ROLES.SEARCH_SCOPES_ERROR_MSG', {
                reason: error.getFullReason(),
              }),
              alertType: ALERT_BANNER_TYPE.DANGER,
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * getAccountsByRoleId$
   * @type {Observable<Action>}
   * @memberof RolesEffects
   */
  public getAccountsByRoleId$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RolesActions.getAccountsByRoleId,
        RolesActions.changeUsersPagination,
        RolesActions.unassignAccountsSuccess,
        RolesActions.assignUsersSuccess,
      ),
      withLatestFrom(this.store.select(RolesSelectors.getAccountsByRoleIdRequest)),
      switchMap(([{ roleId }, request]: [ReturnType<typeof RolesActions.getAccountsByRoleId>, PagedRequest]) => {
        return this.rolesService.getAccountAssignmentsByRoleId(request, roleId).pipe(
          map((response: AccountsSearchResponse) => RolesActions.getAccountsByRoleIdSuccess({ accountsSearchResponse: response })),
          catchError((error: WebError) => [
            RolesActions.getAccountsByRoleIdFailure({ error }),
            AlertBannerActions.showAlertBanner({
              message: this.i18nService.translate('ROLES.ACCOUNTS_SEARCH_ERROR_MSG', {
                reason: error.getFullReason(),
              }),
              alertType: ALERT_BANNER_TYPE.DANGER,
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * Initializes instance of RolesEffects
   * @param {Actions} actions$ - Actions observable instance
   * @param {AccountService} accountService - AccountService instance
   * @param {I18NService} i18nService - I18n Service instance
   * @param {RolesService} rolesService - RolesService instance
   * @param {Store<AdminManagementState>} store - AdminManagementState Store instance
   * @memberof RolesEffects
   */
  constructor(
    private actions$: Actions,
    private accountService: AccountService,
    private i18nService: I18NService,
    private rolesService: RolesService,
    private store: Store<AdminManagementState>,
  ) {}
}
