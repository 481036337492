/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { DataGridComponent, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ADMIN_MANAGEMENT_ROUTE_NAMES } from '@ws1c/admin-management/admin-management-routes-names';
import { AdminManagementConfig } from '@ws1c/admin-management/const';
import {
  AdminGroupsListResponse,
  AdminGroupsSearchResponse,
  AdminGroupsUsersListRequest,
  AdminManagementPagedResponse,
  AdminManagementState,
  IntegrationOrgGroup,
} from '@ws1c/admin-management/models';
import { AdminGroupsService, RolesService } from '@ws1c/admin-management/services';
import { RolesActions, RolesSelectors } from '@ws1c/admin-management/store/roles';
import { I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { AlertBannerActions, NavigationActions, UserPreferenceSelectors } from '@ws1c/intelligence-core';
import {
  AccountRole,
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  BlockerStatus,
  Integration,
  IntegrationDetailsListByKey,
} from '@ws1c/intelligence-models';
import { AdminGroupsActions } from './admin-groups.actions';

/**
 * AdminGroupsEffects
 *
 * @export
 * @class AdminGroupsEffects
 */
@Injectable()
export class AdminGroupsEffects {
  /**
   * editAdminGroup$
   * @type {Observable<Action>}
   * @memberof AdminGroupsEffects
   */
  public editAdminGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminGroupsActions.editAdminGroup),
      mergeMap((action: ReturnType<typeof AdminGroupsActions.editAdminGroup>) => {
        const { platformRoles, serviceRoles } = action.adminGroupListItem;
        return [RolesActions.resetSelectedRoles(), RolesActions.setSelectedRoles({ platformRoles, serviceRoles })];
      }),
      tap(() => this.routerExtensions.navigate([`/${ADMIN_MANAGEMENT_ROUTE_NAMES.ADMIN_GROUPS_EDIT}`])),
    ),
  );

  /**
   * loadAdminGroupsList$
   * @type {Observable<Action>}
   * @memberof AdminGroupsEffects
   */
  public loadAdminGroupsList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminGroupsActions.loadAdminGroupsList),
      withLatestFrom(this.store.select(UserPreferenceSelectors.gemIntegrationDetailsListByType)),
      switchMap(
        ([{ pagedRequest }, integrationDetailsListByKey]: [
          ReturnType<typeof AdminGroupsActions.loadAdminGroupsList>,
          IntegrationDetailsListByKey,
        ]) => {
          let orgGroupListfetched: boolean = false;
          const integrationId: string = integrationDetailsListByKey[Integration.AIRWATCH]?.[0]?.id;
          const serviceCalls: Array<Observable<any>> = [];
          if (integrationId) {
            serviceCalls.push(
              this.rolesService.getOrgGroupListByIntegrationId(integrationId).pipe(
                map((orgGroupList: IntegrationOrgGroup[]) => {
                  orgGroupListfetched = true;
                  return orgGroupList;
                }),
                catchError((webError: WebError) => of(webError)),
              ),
            );
          }
          serviceCalls.push(
            this.adminGroupsService.getAdminGroupsList(
              new AdminGroupsUsersListRequest({
                ...pagedRequest,
              }),
            ),
          );
          return forkJoin(serviceCalls).pipe(
            withLatestFrom(this.store.select(RolesSelectors.serviceRoleById)),
            switchMap(([response, serviceRoleById]: [any[], Map<string, AccountRole>]) => {
              const actions = [];
              if (integrationId && orgGroupListfetched) {
                actions.push(
                  RolesActions.getOrgGroupListByIntegrationIdSuccess({
                    integrationId,
                    orgGroupList: response[0],
                  }),
                );
              }
              if (integrationId && !orgGroupListfetched) {
                actions.push(
                  RolesActions.getOrgGroupListByIntegrationIdFailure({ integrationId }),
                  AlertBannerActions.showAlertBanner({
                    message: this.i18nService.translate('ROLES.GET_ORG_GROUP_HIERARCHY_FAILURE_MSG', {
                      reason: '',
                    }),
                    alertType: ALERT_BANNER_TYPE.DANGER,
                  }),
                );
              }
              actions.push(
                AdminGroupsActions.loadAdminGroupsListSuccess({
                  adminGroupsListResponse: integrationId ? response[1] : response[0],
                  orgGroupList: orgGroupListfetched ? response[0] : undefined,
                  serviceRoleById,
                }),
              );
              return actions;
            }),
            catchError((webError: WebError) => [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate('ADMIN_GROUPS.LOAD_ADMIN_GROUPS_LIST_FAILURE_MSG', {
                  reason: webError.getFullReason(),
                }),
                target: AlertBannerTarget.SECTION,
              }),
              AdminGroupsActions.loadAdminGroupsListFailure(),
            ]),
          );
        },
      ),
    ),
  );

  /**
   * searchAdminGroups$
   * @type {Observable<Action>}
   * @memberof AdminGroupsEffects
   */
  public searchAdminGroups$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminGroupsActions.searchAdminGroups),
      debounceTime(400),
      filter(({ searchTerm }: ReturnType<typeof AdminGroupsActions.searchAdminGroups>) => {
        return searchTerm.length >= AdminManagementConfig.MIN_SEARCH_TERM_LENGTH;
      }),
      switchMap(({ searchTerm }: ReturnType<typeof AdminGroupsActions.searchAdminGroups>) => {
        return this.adminGroupsService.searchAdminGroups(searchTerm).pipe(
          map(({ data }: AdminGroupsSearchResponse) => {
            return AdminGroupsActions.searchAdminGroupsSuccess({
              searchedAdminGroups: data,
            });
          }),
          catchError((webError: WebError) => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('ADMIN_GROUPS.SEARCH_ADMIN_GROUPS_FAILURE_MSG', {
                groupName: searchTerm,
                reason: webError.getFullReason(),
              }),
            }),
            AdminGroupsActions.searchAdminGroupsFailure(),
          ]),
        );
      }),
    ),
  );

  /**
   * goToAdminGroupsListPage$
   * @type {Observable<Action>}
   * @memberof AdminGroupsEffects
   */
  public goToAdminGroupsListPage$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminGroupsActions.goToAdminGroupsListPage),
        tap(() => this.routerExtensions.navigate([`/${ADMIN_MANAGEMENT_ROUTE_NAMES.ADMIN_GROUPS_LIST}`])),
      ),
    { dispatch: false },
  );

  /**
   * inviteAdminGroups$
   * @type {Observable<Action>}
   * @memberof AdminGroupsEffects
   */
  public inviteAdminGroups$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminGroupsActions.inviteAdminGroups),
      switchMap(({ adminGroupsInviteRequest }: ReturnType<typeof AdminGroupsActions.inviteAdminGroups>) => {
        return this.adminGroupsService.inviteAdminGroups(adminGroupsInviteRequest).pipe(
          mergeMap(() => [
            AdminGroupsActions.inviteAdminGroupsSuccess(),
            RolesActions.setRolesConfirmModalOpenState({ isOpen: false }),
            NavigationActions.setBlockerStatus({ blockerStatus: BlockerStatus.SKIP_NEXT_BLOCK }),
            AdminGroupsActions.goToAdminGroupsListPage(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate('ADMIN_GROUPS.INVITE_ADMIN_GROUPS_SUCCESS_MSG', {
                count: adminGroupsInviteRequest.groups.length,
              }),
              target: AlertBannerTarget.SECTION,
            }),
          ]),
          catchError((webError: WebError) => [
            AdminGroupsActions.inviteAdminGroupsFailure(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('ADMIN_GROUPS.INVITE_ADMIN_GROUPS_FAILURE_MSG', {
                reason: webError.getFullReason(),
              }),
              target: AlertBannerTarget.MODAL,
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * removeAdminGroupRoles$
   * @type {Observable<Action>}
   * @memberof AdminGroupsEffects
   */
  public removeAdminGroupRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminGroupsActions.removeAdminGroupRoles),
      switchMap(({ adminGroupListItem }: ReturnType<typeof AdminGroupsActions.removeAdminGroupRoles>) => {
        return this.adminGroupsService.removeAdminGroupRoles(adminGroupListItem).pipe(
          mergeMap(() => [
            AdminGroupsActions.loadAdminGroupsList({
              pagedRequest: {
                from: 0,
                size: DataGridComponent.DEFAULT_PAGE_SIZE,
              },
            }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate('ADMIN_GROUPS.REMOVE_ADMIN_GROUP_ROLES_SUCCESS_MSG', {
                name: adminGroupListItem.displayName,
              }),
              target: AlertBannerTarget.SECTION,
            }),
          ]),
          catchError((webError: WebError) => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('ADMIN_GROUPS.REMOVE_ADMIN_GROUP_ROLES_FAILURE_MSG', {
                name: adminGroupListItem.displayName,
                reason: webError.getFullReason(),
              }),
              target: AlertBannerTarget.SECTION,
            }),
            AdminGroupsActions.loadAdminGroupsListFailure(),
          ]),
        );
      }),
    ),
  );

  /**
   * updateAdminGroupRoles$
   * @type {Observable<Action>}
   * @memberof AdminGroupsEffects
   */
  public updateAdminGroupRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminGroupsActions.updateAdminGroupRoles),
      switchMap(({ adminGroupListItem }: ReturnType<typeof AdminGroupsActions.updateAdminGroupRoles>) => {
        return this.adminGroupsService.updateAdminGroupRoles(adminGroupListItem).pipe(
          mergeMap(() => [
            RolesActions.setRolesConfirmModalOpenState({ isOpen: false }),
            AdminGroupsActions.inviteAdminGroupsSuccess(),
            NavigationActions.setBlockerStatus({ blockerStatus: BlockerStatus.SKIP_NEXT_BLOCK }),
            AdminGroupsActions.goToAdminGroupsListPage(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate('ADMIN_GROUPS.UPDATE_ADMIN_GROUP_ROLES_SUCCESS_MSG', {
                name: adminGroupListItem.displayName,
              }),
              target: AlertBannerTarget.SECTION,
            }),
          ]),
          catchError((webError: WebError) => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('ADMIN_GROUPS.UPDATE_ADMIN_GROUP_ROLES_FAILURE_MSG', {
                name: adminGroupListItem.displayName,
                reason: webError.getFullReason(),
              }),
              target: AlertBannerTarget.SECTION,
            }),
            AdminGroupsActions.inviteAdminGroupsFailure(),
          ]),
        );
      }),
    ),
  );

  /**
   * loadUsersListForGroup$
   * @type {Observable<Action>}
   * @memberof AdminGroupsEffects
   */
  public loadUsersListForGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminGroupsActions.loadUsersListForGroup),
      switchMap(({ usersForGroupRequest, adminGroupListItem }: ReturnType<typeof AdminGroupsActions.loadUsersListForGroup>) => {
        return this.adminGroupsService
          .getUsersListForGroup(new AdminGroupsUsersListRequest({ ...usersForGroupRequest }), adminGroupListItem.directoryGroupId)
          .pipe(
            map((usersListResponseForGroup: AdminManagementPagedResponse) => {
              return AdminGroupsActions.loadUsersListForGroupSuccess({ usersListResponseForGroup });
            }),
            catchError((webError: WebError) => [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate('ADMIN_GROUPS.LOAD_USERS_LIST_FOR_GROUP_FAILURE_MSG', {
                  groupName: adminGroupListItem.displayName,
                  reason: webError.getFullReason(),
                }),
                target: AlertBannerTarget.SECTION,
              }),
              AdminGroupsActions.loadUsersListForGroupFailure(),
            ]),
          );
      }),
    ),
  );

  /**
   * loadGroupsListForGroup$
   * @type {Observable<Action>}
   * @memberof AdminGroupsEffects
   */
  public loadGroupsListForGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminGroupsActions.loadGroupsListForGroup),
      filter(({ adminGroupListItem }: ReturnType<typeof AdminGroupsActions.loadGroupsListForGroup>) => {
        return adminGroupListItem.groupType === AdminManagementConfig.GROUP_TYPE_USER_GROUP;
      }),
      switchMap(({ groupsForGroupRequest, adminGroupListItem }: ReturnType<typeof AdminGroupsActions.loadGroupsListForGroup>) => {
        return this.adminGroupsService
          .getGroupsListForGroup(new AdminGroupsUsersListRequest({ ...groupsForGroupRequest }), adminGroupListItem.directoryGroupId)
          .pipe(
            map((groupsListResponseForGroup: AdminGroupsListResponse) => {
              return AdminGroupsActions.loadGroupsListForGroupSuccess({ groupsListResponseForGroup });
            }),
            catchError((webError: WebError) => [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate('ADMIN_GROUPS.LOAD_GROUPS_LIST_FOR_GROUP_FAILURE_MSG', {
                  groupName: adminGroupListItem.displayName,
                  reason: webError.getFullReason(),
                }),
                target: AlertBannerTarget.SECTION,
              }),
              AdminGroupsActions.loadGroupsListForGroupFailure(),
            ]),
          );
      }),
    ),
  );

  /**
   * Initializes instance of AdminGroupsEffects
   * @param {Actions} actions$ - Actions observable instance
   * @param {AdminGroupsService} adminGroupsService - AdminGroupsService instance
   * @param {I18NService} i18nService - I18n Service instance
   * @param {RolesService} rolesService - RolesService instance
   * @param {RouterExtensions} routerExtensions - Router Extensions Service instance
   * @param {Store<AdminManagementState>} store - AdminManagementState Store instance
   * @memberof AdminGroupsEffects
   */
  constructor(
    private actions$: Actions,
    private adminGroupsService: AdminGroupsService,
    private i18nService: I18NService,
    private rolesService: RolesService,
    private routerExtensions: RouterExtensions,
    private store: Store<AdminManagementState>,
  ) {}
}
