/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { AdminManagementConfig } from '@ws1c/admin-management/const';
import {
  AdminGroupListItem,
  AdminUserListItem,
  IntegrationOrgGroup,
  PendingInvitation,
  PlatformRole,
  PlatformRoleType,
  ServiceRole,
} from '@ws1c/admin-management/models';
import { I18NService } from '@ws1c/intelligence-common';
import {
  AccountRole,
  IntegratedServiceType,
  Integration,
  IntegrationDetails,
  IntegrationDetailsListByKey,
} from '@ws1c/intelligence-models';

/**
 * Returns function to format service roles display name
 * @param {Map<string, PlatformRole>} platformRoleByName - platform role by name map
 * @param {Map<string, AccountRole>} serviceRoleById - service role by id map
 * @type {(role: PlatformRole | ServiceRole) => string}
 */
export const getRoleLabelFormatterFn = (platformRoleByName: Map<string, PlatformRole>, serviceRoleById: Map<string, AccountRole>) => {
  return (role: PlatformRole | ServiceRole) =>
    role instanceof PlatformRole ? platformRoleByName.get(role.name)?.displayName : serviceRoleById.get(role.roleId)?.label;
};

/**
 * Returns function to format service type display name
 * @param {I18NService} i18nService - I18N Service instance
 * @type {(roleOrServiceType: PlatformRole | string) => string}
 */
export const getServiceLabelFormatterFn = (i18nService: I18NService) => {
  return (roleOrServiceType: PlatformRole | string) =>
    i18nService.translate(
      roleOrServiceType instanceof PlatformRole
        ? 'ADMIN_MGMT_COMMON_MESSAGES.ALL_SERVICES'
        : AdminManagementConfig.SERVICE_TYPE_TO_LABEL_MAP[roleOrServiceType],
    );
};

/**
 * Returns platform roles for assignment to newly added/edited admin user or admin group
 * @param {PlatformRole[]} selectedPlatformRoles - list of platform roles
 * @type {(selectedPlatformRoles: PlatformRole[]) => PlatformRole[]}
 */
export const getPlatformRolesForAssignment = (selectedPlatformRoles: PlatformRole[]) => {
  return selectedPlatformRoles.map(({ name }: PlatformRole) => new PlatformRole({ name }));
};

/**
 * Returns service roles for assignment to newly added/edited admin user or admin group
 * @param {AccountRole[]} roles - roles list for service
 * @param {string} tenantUuid - selected org group uuid
 * @param {IntegrationDetails} integrationDetails - service integration instance on which roles are assigned
 * @type {(
 *   roles: AccountRole[],
 *   tenantUuid: string,
 *   integrationDetails: IntegrationDetails
 * ) => ServiceRole[]}
 */
export const getServiceRolesForAssignment = (roles: AccountRole[], tenantUuid: string, integrationDetails: IntegrationDetails) => {
  return roles.map(
    ({ roleId, serviceType }: AccountRole) =>
      new ServiceRole({
        roleId,
        service: serviceType,
        assignedTenantId: tenantUuid,
        rootTenantId: integrationDetails?.tenantUuid,
      }),
  );
};

/**
 * Utils method to return selected platform, intelligence and external service roles
 * @param {PlatformRole[]} platformRoles - platform roles list
 * @param {ServiceRole[]} serviceRoles - service roles list
 * @param {Map<string, PlatformRole>} platformRoleByName - platform role by name map
 * @param {Map<string, AccountRole>} serviceRoleById - service role by id map
 * @param {Partial<Record<IntegratedServiceType, Integration>>} integratedServiceToIntegrationMap - service type to integration map
 * @param {IntegrationDetailsListByKey} gemIntegrationDetailsListByType - integration details list by type
 * @type {(
 *   platformRoles: PlatformRole[],
 *   serviceRoles: ServiceRole[],
 *   platformRoleByName: Map<string, PlatformRole>,
 *   serviceRoleById: Map<string, AccountRole>,
 *   integratedServiceToIntegrationMap: Partial<Record<IntegratedServiceType, Integration>>,
 *   gemIntegrationDetailsListByType: IntegrationDetailsListByKey) => {
 *     selectedPlatformRoles: PlatformRole[];
 *     selectedIntelligenceRoles: AccountRole[];
 *     selectedExternalServiceRolesMap: Record<string, AccountRole[]>;
 *   }
 * }
 */
export const getSelectedRoles = (
  platformRoles: PlatformRole[],
  serviceRoles: ServiceRole[],
  platformRoleByName: Map<string, PlatformRole>,
  serviceRoleById: Map<string, AccountRole>,
  integratedServiceToIntegrationMap: Partial<Record<IntegratedServiceType, Integration>>,
  gemIntegrationDetailsListByType: IntegrationDetailsListByKey,
) => {
  platformRoles.forEach((platformRole: PlatformRole) => {
    const selectedPlatformRoleName: string = platformRole?.name;
    platformRoleByName.set(
      selectedPlatformRoleName,
      new PlatformRole({
        ...platformRoleByName.get(selectedPlatformRoleName),
        isSelected: true,
      }),
    );
  });
  const selectedIntelligenceRoles = [];
  const selectedOrgRolesMap: Record<string, AccountRole[]> = {};
  const selectedExternalServiceRolesMap: Record<string, Record<string, AccountRole[]>> = {};
  serviceRoles.forEach((serviceRole: ServiceRole) => {
    const role: AccountRole = serviceRoleById.get(serviceRole.roleId);
    if (serviceRole.service === IntegratedServiceType.INTELLIGENCE) {
      selectedIntelligenceRoles.push(role);
    } else {
      const tenantId = serviceRole.assignedTenantId;
      const integration: string = integratedServiceToIntegrationMap[serviceRole.service];
      const integrationId = gemIntegrationDetailsListByType[integration]?.[0]?.id;
      const rolesList: AccountRole[] = selectedExternalServiceRolesMap[integrationId]?.[tenantId] || [];
      rolesList.push(role);
      selectedOrgRolesMap[tenantId] = rolesList;
      selectedExternalServiceRolesMap[integrationId] = selectedOrgRolesMap;
    }
  });
  return {
    selectedPlatformRoles: [...platformRoleByName.values()],
    selectedIntelligenceRoles,
    selectedExternalServiceRolesMap,
  };
};

/**
 * Returns map of passed service role property to list of service roles
 * @param {ServiceRole[]} serviceRoles - list of service roles
 * @param {string} serviceRoleProperty - service role property name
 * @returns {Map<string, ServiceRole[]>}
 */
export const serviceRoleByProperty = (serviceRoles: ServiceRole[], serviceRoleProperty: string) => {
  return serviceRoles.reduce((serviceRolesByPropertyMap: Map<string, ServiceRole[]>, serviceRole: ServiceRole) => {
    const serviceRolesMapKey: string = serviceRole[serviceRoleProperty];
    if (serviceRolesMapKey) {
      const serviceRolesList: ServiceRole[] = serviceRolesByPropertyMap.get(serviceRolesMapKey) || [];
      serviceRolesList.push(serviceRole);
      serviceRolesByPropertyMap.set(serviceRolesMapKey, serviceRolesList);
    }
    return serviceRolesByPropertyMap;
  }, new Map<string, ServiceRole[]>());
};

/**
 * Returns map of service to orgGroupIds for selected item
 * @param {ServiceRole[]} serviceRoles
 * @returns {Map<string, Set<string>>}
 */
export const getOrgGroupIdsByServiceType = (serviceRoles: ServiceRole[]) => {
  const orgGroupIdsByServiceType = new Map<string, Set<string>>();
  serviceRoles?.forEach((serviceRole: ServiceRole) => {
    if (serviceRole.assignedTenantId) {
      const orgGroupsSet: Set<string> = orgGroupIdsByServiceType.get(serviceRole.service) || new Set();
      orgGroupsSet.add(serviceRole.assignedTenantId);
      orgGroupIdsByServiceType.set(serviceRole.service, orgGroupsSet);
    }
  });
  return orgGroupIdsByServiceType;
};

/**
 * Returns map of service roles by service type sorted by integrated service type
 * @param {Partial<Record<IntegratedServiceType, Integration>>} integratedServiceToIntegrationMap - service type to integration map
 * @param {string} serviceRolesMap - service roles by service type map
 * @returns {Map<IntegratedServiceType, ServiceRole[]>}
 */
export const serviceRolesByIntegratedService = (
  integratedServiceToIntegrationMap: Partial<Record<IntegratedServiceType, Integration>>,
  serviceRolesMap: Map<IntegratedServiceType, ServiceRole[]>,
) => {
  const serviceRolesByIntegratedServiceTypeMap: Map<IntegratedServiceType, ServiceRole[]> = new Map();
  Object.keys(integratedServiceToIntegrationMap).forEach((service) => {
    const rolesForService = serviceRolesMap.get(IntegratedServiceType[service]);
    if (rolesForService) {
      serviceRolesByIntegratedServiceTypeMap.set(IntegratedServiceType[service], rolesForService);
    }
  });
  return serviceRolesByIntegratedServiceTypeMap;
};

/**
 * Returns index of admin platform role index in platformRoles array
 * @param {PlatformRole[]} platformRoles - list of platform roles
 * @type {(platformRoles: PlatformRole[]) => number}
 */
export const getAdminPlatFormRoleIndex = (platformRoles: PlatformRole[]) => {
  return platformRoles.findIndex((platformRole: PlatformRole) => {
    return platformRole.name === PlatformRoleType.WS_ONE_CLOUD_ADMIN;
  });
};

/**
 * Returns filtered selected platform roles after filtering out group role assignments
 * @param {AdminGroupListItem | AdminUserListItem} selectedAdminUserGroupListItem - selected admin user or group list item
 * @param {PlatformRole[]} selectedPlatformRoles - selected platform roles
 * @returns {PlatformRole[]}
 */
export const getFilteredSelectedPlatformRoles = (
  selectedAdminUserGroupListItem: AdminGroupListItem | AdminUserListItem,
  selectedPlatformRoles: PlatformRole[],
) => {
  const groupMembershipType: string = AdminManagementConfig.GROUP_MEMBERSHIP_TYPE;
  const roleNameToMembershipTypeMap: Map<string, string> = new Map(
    selectedAdminUserGroupListItem.platformRoles?.map((role: PlatformRole) => [role.name, role.membershipType]),
  );
  return selectedPlatformRoles.filter((selectedRole: PlatformRole) => {
    return (
      !roleNameToMembershipTypeMap.has(selectedRole.name) ||
      (roleNameToMembershipTypeMap.has(selectedRole.name) && roleNameToMembershipTypeMap.get(selectedRole.name) !== groupMembershipType)
    );
  });
};

/**
 * Filter out assigned service roles if the role or the OG on which role is assigned is no longer present
 * @param {AdminGroupListItem[] | AdminUserListItem[] | PendingInvitation[]} userList - admin user or group or pending invitation list items
 * @param {IntegrationOrgGroup[]} orgGroupList - list of integration org group objects
 * @param {Map<string, AccountRole>} serviceRoleByIdMap - map of service role by service id
 * @returns {AdminGroupListItem[] | AdminUserListItem[] | PendingInvitation[]}
 */
export const getListWithAssignedServiceRoles = (
  userList: Array<AdminUserListItem | AdminGroupListItem | PendingInvitation>,
  orgGroupList: IntegrationOrgGroup[],
  serviceRoleByIdMap: Map<string, AccountRole>,
) => {
  if (!orgGroupList?.length || !serviceRoleByIdMap.size) {
    return userList;
  }
  const orgGroupListMap: Map<string, IntegrationOrgGroup> = new Map(orgGroupList.map((org: IntegrationOrgGroup) => [org.tenantUuid, org]));
  const filteredUserList: Array<AdminUserListItem | AdminGroupListItem | PendingInvitation> = [];
  userList.forEach((user: AdminUserListItem | AdminGroupListItem | PendingInvitation) => {
    const filteredUserItem: AdminUserListItem | AdminGroupListItem | PendingInvitation = user;
    filteredUserItem.serviceRoles = user.serviceRoles.filter((serviceRole: ServiceRole) => {
      return (
        serviceRole.service === IntegratedServiceType.INTELLIGENCE ||
        (serviceRoleByIdMap.has(serviceRole.roleId) && orgGroupListMap.has(serviceRole.assignedTenantId))
      );
    });
    filteredUserList.push(filteredUserItem);
  });
  return filteredUserList;
};
