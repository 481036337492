/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { AdminGroupListItem } from './admin-group-list-item.model';

/**
 * Admin Groups List Response
 *
 * @export
 * @class AdminGroupsListResponse
 * @implements {PagedResponse}
 */
@Serializable
export class AdminGroupsListResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: AdminGroupListItem })
  public results: AdminGroupListItem[] = undefined;

  /**
   * Initializes instance of AdminGroupsListResponse
   * @param {Array<Partial<AdminGroupsListResponse>>} args - object initialization arguments
   * @memberof AdminGroupsListResponse
   */
  constructor(...args: Array<Partial<AdminGroupsListResponse>>) {
    Object.assign(this, ...args);
  }
}
