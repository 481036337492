/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Service Role
 *
 * @export
 * @class ServiceRole
 */
@Serializable
export class ServiceRole {
  @JsonProperty('role_id')
  public roleId: string = undefined;

  @JsonProperty('service')
  public service: string = undefined;

  @JsonProperty('root_tenant_id')
  public rootTenantId: string = undefined;

  @JsonProperty('assigned_tenant_id')
  public assignedTenantId: string = undefined;

  @JsonProperty('membership_type')
  public membershipType: string = undefined;

  /**
   * Initializes instance of ServiceRole
   * @param {Array<Partial<ServiceRole>>} args - object initialization arguments
   * @memberof ServiceRole
   */
  constructor(...args: Array<Partial<ServiceRole>>) {
    Object.assign(this, ...args);
  }
}
