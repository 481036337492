/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { DataGridComponent, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ADMIN_MANAGEMENT_ROUTE_NAMES } from '@ws1c/admin-management/admin-management-routes-names';
import { AdminManagementConfig } from '@ws1c/admin-management/const';
import {
  AccountMigrationStatusRequest,
  AdminGroupsUsersListRequest,
  AdminManagementState,
  AdminUsersSearchResponse,
  IntegrationOrgGroup,
} from '@ws1c/admin-management/models';
import { AdminUsersService, RolesService } from '@ws1c/admin-management/services';
import { AdminsInvitationActions, AdminsInvitationSelectors } from '@ws1c/admin-management/store/admins-invitation';
import { RolesActions, RolesSelectors } from '@ws1c/admin-management/store/roles';
import { I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { AlertBannerActions, NavigationActions, UserPreferenceSelectors } from '@ws1c/intelligence-core';
import {
  AccountRole,
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  BlockerStatus,
  Integration,
  IntegrationDetailsListByKey,
  RequestStatusType,
} from '@ws1c/intelligence-models';
import { AdminUsersActions } from './admin-users.actions';

/**
 * AdminUsersEffects
 *
 * @export
 * @class AdminUsersEffects
 */
@Injectable()
export class AdminUsersEffects {
  /**
   * editAdminUser$
   * @type {Observable<Action>}
   * @memberof AdminUsersEffects
   */
  public editAdminUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersActions.editAdminUser),
      mergeMap((action: ReturnType<typeof AdminUsersActions.editAdminUser>) => {
        const { platformRoles, serviceRoles } = action.adminUserListItem;
        return [RolesActions.resetSelectedRoles(), RolesActions.setSelectedRoles({ platformRoles, serviceRoles })];
      }),
      tap(() => this.routerExtensions.navigate([`/${ADMIN_MANAGEMENT_ROUTE_NAMES.ADMIN_USERS_EDIT}`])),
    ),
  );

  /**
   * searchAdminUsers$
   * @type {Observable<Action>}
   * @memberof AdminUsersEffects
   */
  public searchAdminUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersActions.searchAdminUsers),
      debounceTime(400),
      filter(({ searchTerm }: ReturnType<typeof AdminUsersActions.searchAdminUsers>) => {
        return searchTerm.length >= AdminManagementConfig.MIN_SEARCH_TERM_LENGTH;
      }),
      switchMap(({ searchTerm }: ReturnType<typeof AdminUsersActions.searchAdminUsers>) => {
        return this.adminUsersService.searchAdminUsers(searchTerm).pipe(
          map(({ data }: AdminUsersSearchResponse) => {
            return AdminUsersActions.searchAdminUsersSuccess({
              searchedAdminUsers: data,
            });
          }),
          catchError((webError: WebError) => [
            AlertBannerActions.showAlertBanner({
              message: this.i18nService.translate('ADMINS.SEARCH_ADMINS_FAILURE_MSG', {
                userName: searchTerm,
                reason: webError.getFullReason(),
              }),
              alertType: ALERT_BANNER_TYPE.DANGER,
            }),
            AdminUsersActions.searchAdminUsersFailure(),
          ]),
        );
      }),
    ),
  );

  /**
   * goToAdminUsersListPage$
   * @type {Observable<Action>}
   * @memberof AdminUsersEffects
   */
  public goToAdminUsersListPage$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminUsersActions.goToAdminUsersListPage),
        tap(() => this.routerExtensions.navigate([`/${ADMIN_MANAGEMENT_ROUTE_NAMES.ADMIN_USERS_LIST}`])),
      ),
    { dispatch: false },
  );

  /**
   * inviteAdminUsers$
   * @type {Observable<Action>}
   * @memberof AdminUsersEffects
   */
  public inviteAdminUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersActions.inviteAdminUsers),
      withLatestFrom(this.store.select(AdminsInvitationSelectors.getSelectedAccountId)),
      switchMap(
        ([{ adminUsersInviteRequest, hasExternalAdmins }, selectedAccountId]: [
          ReturnType<typeof AdminUsersActions.inviteAdminUsers>,
          string,
        ]) => {
          return this.adminUsersService.inviteAdminUsers(adminUsersInviteRequest).pipe(
            mergeMap(() => {
              let actions = [];
              if (selectedAccountId) {
                actions.push(
                  AdminUsersActions.updateAccountMigrationStatus({
                    accountMigrationStatusRequest: new AccountMigrationStatusRequest({
                      accountId: selectedAccountId,
                      email: adminUsersInviteRequest.users[0].email,
                      cspMigrationStatus: RequestStatusType.GRANTED,
                    }),
                  }),
                );
              }
              actions = actions.concat([
                AdminUsersActions.inviteAdminUsersSuccess(),
                RolesActions.setRolesConfirmModalOpenState({ isOpen: false }),
                NavigationActions.setBlockerStatus({ blockerStatus: BlockerStatus.SKIP_NEXT_BLOCK }),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  autoDismiss: false,
                  message: this.i18nService.translate('ADMINS.INVITE_ADMIN_USERS_SUCCESS_MSG', {
                    count: adminUsersInviteRequest.users.length,
                  }),
                  target: AlertBannerTarget.SECTION,
                }),
                hasExternalAdmins ? AdminsInvitationActions.goToAdminsInvitationListPage() : AdminUsersActions.goToAdminUsersListPage(),
              ]);
              return actions;
            }),
            catchError((webError: WebError) => [
              AdminUsersActions.inviteAdminUsersFailure(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate('ADMINS.INVITE_ADMIN_USERS_FAILURE_MSG', {
                  reason: webError.getFullReason(),
                }),
                target: AlertBannerTarget.MODAL,
              }),
            ]),
          );
        },
      ),
    ),
  );

  /**
   * updateAccountMigrationStatus$
   * @type {Observable<Action>}
   * @memberof AdminUsersEffects
   */
  public updateAccountMigrationStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersActions.updateAccountMigrationStatus),
      switchMap(({ accountMigrationStatusRequest }: ReturnType<typeof AdminUsersActions.updateAccountMigrationStatus>) => {
        return this.adminUsersService
          .updateAccountMigrationStatus(accountMigrationStatusRequest)
          .pipe(map(() => AdminsInvitationActions.setSelectedAccountId({ selectedAccountId: undefined })));
      }),
    ),
  );

  /**
   * loadAdminUsersList$
   * @type {Observable<Action>}
   * @memberof AdminUsersEffects
   */
  public loadAdminUsersList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersActions.loadAdminUsersList),
      withLatestFrom(this.store.select(UserPreferenceSelectors.gemIntegrationDetailsListByType)),
      switchMap(
        ([{ pagedRequest }, integrationDetailsListByKey]: [
          ReturnType<typeof AdminUsersActions.loadAdminUsersList>,
          IntegrationDetailsListByKey,
        ]) => {
          let orgGroupListfetched: boolean = false;
          const integrationId: string = integrationDetailsListByKey[Integration.AIRWATCH]?.[0]?.id;
          const serviceCalls: Array<Observable<any>> = [];
          if (integrationId) {
            serviceCalls.push(
              this.rolesService.getOrgGroupListByIntegrationId(integrationId).pipe(
                map((orgGroupList: IntegrationOrgGroup[]) => {
                  orgGroupListfetched = true;
                  return orgGroupList;
                }),
                catchError((webError: WebError) => of(webError)),
              ),
            );
          }
          serviceCalls.push(
            this.adminUsersService.getAdminUsersList(
              new AdminGroupsUsersListRequest({
                ...pagedRequest,
              }),
            ),
          );
          return forkJoin(serviceCalls).pipe(
            withLatestFrom(this.store.select(RolesSelectors.serviceRoleById)),
            switchMap(([response, serviceRoleById]: [any[], Map<string, AccountRole>]) => {
              const actions = [];
              if (integrationId && orgGroupListfetched) {
                actions.push(
                  RolesActions.getOrgGroupListByIntegrationIdSuccess({
                    integrationId,
                    orgGroupList: response[0],
                  }),
                );
              }
              if (integrationId && !orgGroupListfetched) {
                actions.push(
                  RolesActions.getOrgGroupListByIntegrationIdFailure({ integrationId }),
                  AlertBannerActions.showAlertBanner({
                    message: this.i18nService.translate('ROLES.GET_ORG_GROUP_HIERARCHY_FAILURE_MSG', {
                      reason: '',
                    }),
                    alertType: ALERT_BANNER_TYPE.DANGER,
                  }),
                );
              }
              actions.push(
                AdminUsersActions.loadAdminUsersListSuccess({
                  adminUsersListResponse: integrationId ? response[1] : response[0],
                  orgGroupList: orgGroupListfetched ? response[0] : undefined,
                  serviceRoleById,
                }),
              );
              return actions;
            }),
            catchError((webError: WebError) => [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate('ADMINS.LOAD_ADMIN_USERS_LIST_FAILURE_MSG', {
                  reason: webError.getFullReason(),
                }),
                target: AlertBannerTarget.SECTION,
              }),
              AdminUsersActions.loadAdminUsersListFailure(),
            ]),
          );
        },
      ),
    ),
  );

  /**
   * removeAdminUserRoles$
   * @type {Observable<Action>}
   * @memberof AdminUsersEffects
   */
  public removeAdminUserRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersActions.removeAdminUserRoles),
      switchMap(({ adminUserListItem }: ReturnType<typeof AdminUsersActions.removeAdminUserRoles>) => {
        return this.adminUsersService.removeAdminUserRoles(adminUserListItem).pipe(
          mergeMap(() => [
            AdminUsersActions.loadAdminUsersList({
              pagedRequest: {
                from: 0,
                size: DataGridComponent.DEFAULT_PAGE_SIZE,
              },
            }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate('ADMINS.REMOVE_ADMIN_USER_ROLES_SUCCESS_MSG', {
                name: adminUserListItem?.user?.displayName,
              }),
              target: AlertBannerTarget.SECTION,
            }),
          ]),
          catchError((webError: WebError) => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('ADMINS.REMOVE_ADMIN_USER_ROLES_FAILURE_MSG', {
                name: adminUserListItem?.user?.displayName,
                reason: webError.getFullReason(),
              }),
              target: AlertBannerTarget.SECTION,
            }),
            AdminUsersActions.loadAdminUsersListFailure(),
          ]),
        );
      }),
    ),
  );

  /**
   * updateAdminUserRoles$
   * @type {Observable<Action>}
   * @memberof AdminUsersEffects
   */
  public updateAdminUserRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminUsersActions.updateAdminUserRoles),
      switchMap(({ adminUserListItem }: ReturnType<typeof AdminUsersActions.updateAdminUserRoles>) => {
        return this.adminUsersService.updateAdminUserRoles(adminUserListItem).pipe(
          mergeMap(() => [
            RolesActions.setRolesConfirmModalOpenState({ isOpen: false }),
            AdminUsersActions.inviteAdminUsersSuccess(),
            NavigationActions.setBlockerStatus({ blockerStatus: BlockerStatus.SKIP_NEXT_BLOCK }),
            AdminUsersActions.goToAdminUsersListPage(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              message: this.i18nService.translate('ADMINS.UPDATE_ADMIN_USER_ROLES_SUCCESS_MSG', {
                name: adminUserListItem.displayName,
              }),
              target: AlertBannerTarget.SECTION,
            }),
          ]),
          catchError((webError: WebError) => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('ADMINS.UPDATE_ADMIN_USER_ROLES_FAILURE_MSG', {
                name: adminUserListItem.displayName,
                reason: webError.getFullReason(),
              }),
              target: AlertBannerTarget.SECTION,
            }),
            AdminUsersActions.inviteAdminUsersFailure(),
          ]),
        );
      }),
    ),
  );

  /**
   * Initializes instance of AdminUsersEffects
   * @param {Actions} actions$ - Actions observable instance
   * @param {AdminUsersService} adminUsersService - AdminUsersService instance
   * @param {I18NService} i18nService - I18n Service instance
   * @param {RolesService} rolesService - RolesService instance
   * @param {RouterExtensions} routerExtensions - Router Extensions Service instance
   * @param {Store<AdminManagementState>} store - AdminManagementState Store instance
   * @memberof AdminUsersEffects
   */
  constructor(
    private actions$: Actions,
    private adminUsersService: AdminUsersService,
    private i18nService: I18NService,
    private rolesService: RolesService,
    private routerExtensions: RouterExtensions,
    private store: Store<AdminManagementState>,
  ) {}
}
