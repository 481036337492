/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Action, createReducer, on } from '@ngrx/store';

import { AdminManagementConfig } from '@ws1c/admin-management/const';
import { AdminGroupListItem, AdminGroupsListResponse, AdminGroupsState, AdminManagementPagedResponse } from '@ws1c/admin-management/models';
import { getListWithAssignedServiceRoles } from '@ws1c/admin-management/utils';
import { AdminGroupsActions } from './admin-groups.actions';
import { initialAdminGroupsState } from './admin-groups.state';

/**
 * _reducer
 * @type {ActionReducer<AdminGroupsState, Action>}
 */
const _reducer = createReducer(
  initialAdminGroupsState,

  on(
    AdminGroupsActions.inviteAdminGroups,
    AdminGroupsActions.updateAdminGroupRoles,
    (state: AdminGroupsState): AdminGroupsState => ({
      ...state,
      isInvitingAdminGroups: true,
    }),
  ),

  on(
    AdminGroupsActions.inviteAdminGroupsSuccess,
    AdminGroupsActions.inviteAdminGroupsFailure,
    (state: AdminGroupsState): AdminGroupsState => ({
      ...state,
      isInvitingAdminGroups: false,
    }),
  ),

  on(
    AdminGroupsActions.showLoadingAdminGroupsList,
    AdminGroupsActions.loadAdminGroupsList,
    AdminGroupsActions.removeAdminGroupRoles,
    (state: AdminGroupsState): AdminGroupsState => ({
      ...state,
      isLoadingAdminGroupsList: true,
      selectedAdminGroupListItem: null,
    }),
  ),

  on(
    AdminGroupsActions.loadAdminGroupsListSuccess,
    (
      state: AdminGroupsState,
      { adminGroupsListResponse, orgGroupList, serviceRoleById }: ReturnType<typeof AdminGroupsActions.loadAdminGroupsListSuccess>,
    ): AdminGroupsState => {
      adminGroupsListResponse.results = getListWithAssignedServiceRoles(
        adminGroupsListResponse.results,
        orgGroupList,
        serviceRoleById,
      ) as AdminGroupListItem[];
      return {
        ...state,
        adminGroupsListResponse,
        isLoadingAdminGroupsList: false,
      };
    },
  ),

  on(
    AdminGroupsActions.loadAdminGroupsListFailure,
    (state: AdminGroupsState): AdminGroupsState => ({
      ...state,
      isLoadingAdminGroupsList: false,
    }),
  ),

  on(
    AdminGroupsActions.searchAdminGroups,
    (state: AdminGroupsState, { searchTerm }: ReturnType<typeof AdminGroupsActions.searchAdminGroups>): AdminGroupsState => ({
      ...state,
      isLoadingSearchResults: (searchTerm || '').length >= AdminManagementConfig.MIN_SEARCH_TERM_LENGTH,
      searchedAdminGroups: searchTerm ? state.searchedAdminGroups : [],
    }),
  ),

  on(
    AdminGroupsActions.searchAdminGroupsSuccess,
    (
      state: AdminGroupsState,
      { searchedAdminGroups }: ReturnType<typeof AdminGroupsActions.searchAdminGroupsSuccess>,
    ): AdminGroupsState => ({
      ...state,
      isLoadingSearchResults: false,
      searchedAdminGroups,
    }),
  ),

  on(
    AdminGroupsActions.searchAdminGroupsFailure,
    (state: AdminGroupsState): AdminGroupsState => ({
      ...state,
      isLoadingSearchResults: false,
    }),
  ),

  on(
    AdminGroupsActions.setSelectedAdminGroupListItem,
    (
      state: AdminGroupsState,
      { selectedAdminGroupListItem }: ReturnType<typeof AdminGroupsActions.setSelectedAdminGroupListItem>,
    ): AdminGroupsState => ({
      ...state,
      selectedAdminGroupListItem,
    }),
  ),

  on(
    AdminGroupsActions.loadUsersListForGroup,
    (state: AdminGroupsState): AdminGroupsState => ({
      ...state,
      usersListResponseForGroup: new AdminManagementPagedResponse(),
      isLoadingUsersListForGroup: true,
    }),
  ),

  on(
    AdminGroupsActions.loadUsersListForGroupSuccess,
    (
      state: AdminGroupsState,
      { usersListResponseForGroup }: ReturnType<typeof AdminGroupsActions.loadUsersListForGroupSuccess>,
    ): AdminGroupsState => ({
      ...state,
      usersListResponseForGroup,
      isLoadingUsersListForGroup: false,
    }),
  ),

  on(
    AdminGroupsActions.loadUsersListForGroupFailure,
    (state: AdminGroupsState): AdminGroupsState => ({
      ...state,
      isLoadingUsersListForGroup: false,
    }),
  ),

  on(
    AdminGroupsActions.loadGroupsListForGroup,
    (state: AdminGroupsState): AdminGroupsState => ({
      ...state,
      groupsListResponseForGroup: new AdminGroupsListResponse(),
      isLoadingGroupsListForGroup: true,
    }),
  ),

  on(
    AdminGroupsActions.loadGroupsListForGroupSuccess,
    (
      state: AdminGroupsState,
      { groupsListResponseForGroup }: ReturnType<typeof AdminGroupsActions.loadGroupsListForGroupSuccess>,
    ): AdminGroupsState => ({
      ...state,
      groupsListResponseForGroup,
      isLoadingGroupsListForGroup: false,
    }),
  ),

  on(
    AdminGroupsActions.loadGroupsListForGroupFailure,
    (state: AdminGroupsState): AdminGroupsState => ({
      ...state,
      isLoadingGroupsListForGroup: false,
    }),
  ),
);

/**
 * Creates and returns AdminGroupsState
 * @export
 * @param {AdminGroupsState} state - Holds state object of type AdminGroupsState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {AdminGroupsState}
 */
export function adminGroupsReducer(state: AdminGroupsState, action: Action): AdminGroupsState {
  return _reducer(state, action);
}
