/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { PendingInvitation, UsersExistenceStatusResponse } from '@ws1c/admin-management/models';
import { Endpoint, GenericResponse, HttpService } from '@ws1c/intelligence-common';

/**
 * Invited Admins Service
 *
 * @export
 * @class InvitedAdminsService
 */
@Injectable()
export class InvitedAdminsService {
  /**
   * Initializes instance of InvitedAdminsService
   * @param {HttpService} httpService - Instance of HttpService
   * @memberof InvitedAdminsService
   */
  constructor(private httpService: HttpService) {}

  /**
   * Checks for admins email existence
   * @param {string[]} emails - admins email list
   * @returns {Observable<UsersExistenceStatusResponse>}
   * @memberof InvitedAdminsService
   */
  public getUsersExistenceStatus(emails: string[]): Observable<UsersExistenceStatusResponse> {
    return this.httpService.post(Endpoint.USERS_EXISTENCE, emails).pipe(
      map((response: GenericResponse) => deserialize(UsersExistenceStatusResponse, response)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Get pending invitations list
   * @returns {Observable<PendingInvitation[]>}
   * @memberof InvitedAdminsService
   */
  public getPendingInvitations(): Observable<PendingInvitation[]> {
    return this.httpService.get(Endpoint.PENDING_INVITATIONS).pipe(
      map((response: GenericResponse) => {
        return response.data.map((pendingInvitation: GenericObject) => {
          return deserialize(PendingInvitation, pendingInvitation);
        });
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Method to notify invite for pending invitation
   * @param {string[]} userNames
   * @returns {Observable<boolean>}
   * @memberof InvitedAdminsService
   */
  public notifyInvitedAdmin(userNames: string[]): Observable<boolean> {
    return this.httpService.post(Endpoint.INVITE_RESEND, userNames).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Method to revoke pending invitation
   * @param {string[]} userNames
   * @returns {Observable<boolean>}
   * @memberof InvitedAdminsService
   */
  public revokeAdminInvitation(userNames: string[]): Observable<boolean> {
    return this.httpService.post(Endpoint.INVITE_REVOKE, userNames).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }
}
