/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ADMIN_MANAGEMENT_ROUTE_NAMES } from '@ws1c/admin-management/admin-management-routes-names';
import {
  AdminManagementState,
  IntegrationOrgGroup,
  UserExistenceStatus,
  UsersExistenceStatusResponse,
} from '@ws1c/admin-management/models';
import { InvitedAdminsService, RolesService } from '@ws1c/admin-management/services';
import { RolesActions, RolesSelectors } from '@ws1c/admin-management/store/roles';
import { I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { AlertBannerActions, UserPreferenceSelectors } from '@ws1c/intelligence-core';
import {
  AccountRole,
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  Integration,
  IntegrationDetailsListByKey,
  LOAD_STATE,
} from '@ws1c/intelligence-models';
import { AdminsInvitationActions } from './admins-invitation.actions';
import { AdminsInvitationSelectors } from './admins-invitation.selectors';

/**
 * AdminsInvitationEffects
 *
 * @export
 * @class AdminsInvitationEffects
 */
@Injectable()
export class AdminsInvitationEffects {
  /**
   * getUsersExistenceStatus$
   * @type {Observable<Action>}
   * @memberof AdminsInvitationEffects
   */
  public getUsersExistenceStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminsInvitationActions.getUsersExistenceStatus),
      withLatestFrom(this.store.select(AdminsInvitationSelectors.currentAdminsEmailList)),
      filter(([_action, emails]: [Action, string[]]) => !!emails.length),
      switchMap(([_action, emails]: [Action, string[]]) => {
        return this.invitedAdminsService.getUsersExistenceStatus(emails).pipe(
          map(({ data }: UsersExistenceStatusResponse) => {
            return AdminsInvitationActions.setUsersExistenceStatus({
              status: LOAD_STATE.SUCCESS,
              usersExistenceStatus: data,
            });
          }),
          catchError(() => [
            AdminsInvitationActions.setUsersExistenceStatus({
              status: LOAD_STATE.FAILURE,
              usersExistenceStatus: emails.map((email: string) => {
                return new UserExistenceStatus({
                  userName: email,
                });
              }),
            }),
            AlertBannerActions.showAlertBanner({
              message: this.i18nService.translate('INVITED_ADMINS.USERS_EXISTENCE_STATUS_FAILURE_MSG', {
                emails,
              }),
              alertType: ALERT_BANNER_TYPE.DANGER,
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * goToAdminsInvitationListPage$
   * @type {Observable<Action>}
   * @memberof AdminsInvitationEffects
   */
  public goToAdminsInvitationListPage$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminsInvitationActions.goToAdminsInvitationListPage),
        tap(() => this.routerExtensions.navigate([`/${ADMIN_MANAGEMENT_ROUTE_NAMES.ADMINS_INVITATION_LIST}`])),
      ),
    { dispatch: false },
  );

  /**
   * loadPendingInvitationsList$
   * @type {Observable<Action>}
   * @memberof AdminsInvitationEffects
   */
  public loadPendingInvitationsList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminsInvitationActions.loadPendingInvitationsList),
      withLatestFrom(this.store.select(UserPreferenceSelectors.gemIntegrationDetailsListByType)),
      switchMap(
        ([_action, integrationDetailsListByKey]: [
          ReturnType<typeof AdminsInvitationActions.loadPendingInvitationsList>,
          IntegrationDetailsListByKey,
        ]) => {
          let orgGroupListfetched: boolean = false;
          const integrationId: string = integrationDetailsListByKey[Integration.AIRWATCH]?.[0]?.id;
          const serviceCalls: Array<Observable<any>> = [];
          if (integrationId) {
            serviceCalls.push(
              this.rolesService.getOrgGroupListByIntegrationId(integrationId).pipe(
                map((orgGroupList: IntegrationOrgGroup[]) => {
                  orgGroupListfetched = true;
                  return orgGroupList;
                }),
                catchError((webError: WebError) => of(webError)),
              ),
            );
          }
          serviceCalls.push(this.invitedAdminsService.getPendingInvitations());
          return forkJoin(serviceCalls).pipe(
            withLatestFrom(this.store.select(RolesSelectors.serviceRoleById)),
            switchMap(([response, serviceRoleById]: [any[], Map<string, AccountRole>]) => {
              const actions = [];
              if (integrationId && orgGroupListfetched) {
                actions.push(
                  RolesActions.getOrgGroupListByIntegrationIdSuccess({
                    integrationId,
                    orgGroupList: response[0],
                  }),
                );
              }
              if (integrationId && !orgGroupListfetched) {
                actions.push(
                  RolesActions.getOrgGroupListByIntegrationIdFailure({ integrationId }),
                  AlertBannerActions.showAlertBanner({
                    message: this.i18nService.translate('ROLES.GET_ORG_GROUP_HIERARCHY_FAILURE_MSG', {
                      reason: '',
                    }),
                    alertType: ALERT_BANNER_TYPE.DANGER,
                  }),
                );
              }
              actions.push(
                AdminsInvitationActions.loadPendingInvitationsListSuccess({
                  pendingInvitationsList: integrationId ? response[1] : response[0],
                  orgGroupList: orgGroupListfetched ? response[0] : undefined,
                  serviceRoleById,
                }),
              );
              return actions;
            }),
            catchError((webError: WebError) => [
              AdminsInvitationActions.loadPendingInvitationsListFailure(),
              AlertBannerActions.showAlertBanner({
                message: this.i18nService.translate('INVITED_ADMINS.GET_PENDING_INVITATIONS_FAILURE_MSG', {
                  reason: webError.getFullReason(),
                }),
                alertType: ALERT_BANNER_TYPE.DANGER,
              }),
            ]),
          );
        },
      ),
    ),
  );

  /**
   * notifyInvitedAdmin$
   * @type {Observable<Action>}
   * @memberof AdminsInvitationEffects
   */
  public notifyInvitedAdmin$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminsInvitationActions.notifyInvitedAdmin),
      withLatestFrom(this.store.select(AdminsInvitationSelectors.selectedInvitedAdminsUserNames)),
      filter(([_action, emails]: [Action, string[]]) => !!emails.length),
      switchMap(([_action, emails]: [Action, string[]]) => {
        return this.invitedAdminsService.notifyInvitedAdmin(emails).pipe(
          mergeMap(() => [
            AdminsInvitationActions.notifyInvitedAdminSuccess(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              autoDismiss: false,
              message: this.i18nService.translate('INVITED_ADMINS.NOTIFY_INVITED_ADMIN_SUCCESS_MSG', {
                count: emails.length,
              }),
              target: AlertBannerTarget.SECTION,
            }),
          ]),
          catchError((webError: WebError) => [
            AdminsInvitationActions.notifyInvitedAdminFailure(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('INVITED_ADMINS.NOTIFY_INVITED_ADMIN_FAILURE_MSG', {
                reason: webError.getFullReason(),
              }),
              target: AlertBannerTarget.SECTION,
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * revokeAdminInvitation$
   * @type {Observable<Action>}
   * @memberof AdminsInvitationEffects
   */
  public revokeAdminInvitation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminsInvitationActions.revokeAdminInvitation),
      withLatestFrom(this.store.select(AdminsInvitationSelectors.selectedInvitedAdminsUserNames)),
      filter(([_action, emails]: [Action, string[]]) => !!emails.length),
      switchMap(([_action, emails]: [Action, string[]]) => {
        return this.invitedAdminsService.revokeAdminInvitation(emails).pipe(
          mergeMap(() => [
            AdminsInvitationActions.revokeAdminInvitationSuccess(),
            AdminsInvitationActions.loadPendingInvitationsList(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.SUCCESS,
              autoDismiss: false,
              message: this.i18nService.translate('INVITED_ADMINS.REVOKE_ADMIN_INVITATION_SUCCESS_MSG', {
                count: emails.length,
              }),
              target: AlertBannerTarget.SECTION,
            }),
          ]),
          catchError((webError: WebError) => [
            AdminsInvitationActions.revokeAdminInvitationFailure(),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              message: this.i18nService.translate('INVITED_ADMINS.REVOKE_ADMIN_INVITATION_FAILURE_MSG', {
                reason: webError.getFullReason(),
              }),
              target: AlertBannerTarget.SECTION,
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * Initializes instance of AdminsInvitationEffects
   * @param {Actions} actions$ - Actions observable instance
   * @param {InvitedAdminsService} invitedAdminsService - InvitedAdminsService instance
   * @param {I18NService} i18nService - I18n Service instance
   * @param {RolesService} rolesService - RolesService instance
   * @param {RouterExtensions} routerExtensions - Router extensions instance
   * @param {Store<AdminManagementState>} store - Admin Management store instance
   * @memberof AdminsInvitationEffects
   */
  constructor(
    private actions$: Actions,
    private invitedAdminsService: InvitedAdminsService,
    private i18nService: I18NService,
    private rolesService: RolesService,
    private routerExtensions: RouterExtensions,
    private store: Store<AdminManagementState>,
  ) {}
}
