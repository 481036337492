/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Admin User
 *
 * @export
 * @class AdminUser
 */
@Serializable
export class AdminUser {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('directory_user_id')
  public directoryUserId: string = undefined;

  @JsonProperty('email')
  public email: string = undefined;

  @JsonProperty('first_name')
  public firstName: string = undefined;

  @JsonProperty('last_name')
  public lastName: string = undefined;

  @JsonProperty('username')
  public userName: string = undefined;

  @JsonProperty('csp_org_role_present')
  public hasCspOrgRole: boolean = undefined;

  @JsonProperty('ws1c_service_role_present')
  public hasWSOneCloudRole: boolean = undefined;

  /**
   * Initializes instance of AdminUser
   * @param {Array<Partial<AdminUser>>} args - object initialization arguments
   * @memberof AdminUser
   */
  constructor(...args: Array<Partial<AdminUser>>) {
    return Object.assign(this, ...args);
  }

  /**
   * Getter for displayName
   * @type {string}
   * @memberof AdminUserListItem
   */
  public get displayName(): string {
    return this.firstName + ' ' + this.lastName;
  }
}
