/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AdminManagementState, AdminsInvitationState, EmailLookupDetails, PendingInvitation } from '@ws1c/admin-management/models';
import { selectAdminManagementState } from '@ws1c/admin-management/store/admin-management.selector';
import { LOAD_STATE } from '@ws1c/intelligence-models';

/**
 * AdminsInvitationSelectors
 * @export
 * @class AdminsInvitationSelectors
 */
export class AdminsInvitationSelectors {
  /**
   * Selects the Invited admins state slice from Admin Management store
   * @static
   * @type {MemoizedSelector<AdminManagementState, AdminsInvitationState>}
   * @memberof AdminsInvitationSelectors
   */
  public static readonly selectInvitedAdminsState: MemoizedSelector<AdminManagementState, AdminsInvitationState> = createSelector(
    selectAdminManagementState,
    (state: AdminManagementState) => state.invitedAdmins,
  );

  /**
   * adminsEmailLookupDetails
   * @static
   * @type {MemoizedSelector<AdminManagementState, EmailLookupDetails[]>}
   * @memberof AdminsInvitationSelectors
   */
  public static adminsEmailLookupDetailsList: MemoizedSelector<AdminManagementState, EmailLookupDetails[]> = createSelector(
    AdminsInvitationSelectors.selectInvitedAdminsState,
    (state: AdminsInvitationState) => [...state.adminsEmailLookupDetailsMap.values()],
  );

  /**
   * areAdminsEmailLookupSuccessful
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof AdminsInvitationSelectors
   */
  public static areAdminsEmailLookupSuccessful: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    AdminsInvitationSelectors.adminsEmailLookupDetailsList,
    (adminsEmailLookupDetails: EmailLookupDetails[]) => {
      return (
        !!adminsEmailLookupDetails.length &&
        adminsEmailLookupDetails.every((emailLookupDetails: EmailLookupDetails) => {
          return emailLookupDetails.status === LOAD_STATE.SUCCESS && emailLookupDetails.isAllowed;
        })
      );
    },
  );

  /**
   * areAdminsEmailValid
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof AdminsInvitationSelectors
   */
  public static areAdminsEmailValid: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    AdminsInvitationSelectors.adminsEmailLookupDetailsList,
    (adminsEmailLookupDetails: EmailLookupDetails[]) => {
      return adminsEmailLookupDetails.every((emailLookupDetails: EmailLookupDetails) => {
        return emailLookupDetails.status === LOAD_STATE.IN_FLIGHT || emailLookupDetails.isAllowed;
      });
    },
  );

  /**
   * notAllowedAdminsEmailList
   * @static
   * @type {MemoizedSelector<AdminManagementState, string[]>}
   * @memberof AdminsInvitationSelectors
   */
  public static notAllowedAdminsEmailList: MemoizedSelector<AdminManagementState, string[]> = createSelector(
    AdminsInvitationSelectors.adminsEmailLookupDetailsList,
    (adminsEmailLookupDetails: EmailLookupDetails[]) => {
      return adminsEmailLookupDetails.reduce((notAllowedAdminsEmailList: string[], emailLookupDetails: EmailLookupDetails) => {
        if (emailLookupDetails.status === LOAD_STATE.SUCCESS && !emailLookupDetails.isAllowed) {
          notAllowedAdminsEmailList.push(emailLookupDetails.email);
        }
        return notAllowedAdminsEmailList;
      }, []);
    },
  );

  /**
   * currentUserEmailsList
   * @static
   * @type {MemoizedSelector<AdminManagementState, string[]>}
   * @memberof AdminsInvitationSelectors
   */
  public static currentAdminsEmailList: MemoizedSelector<AdminManagementState, string[]> = createSelector(
    AdminsInvitationSelectors.adminsEmailLookupDetailsList,
    (adminsEmailLookupDetails: EmailLookupDetails[]) => {
      return adminsEmailLookupDetails.reduce((userEmailsList: string[], emailLookupDetails: EmailLookupDetails) => {
        if (emailLookupDetails.status === LOAD_STATE.IN_FLIGHT) {
          userEmailsList.push(emailLookupDetails.email);
        }
        return userEmailsList;
      }, []);
    },
  );

  /**
   * adminsEmailLookupDetailsMap
   * @static
   * @type {MemoizedSelector<AdminManagementState, Map<string, EmailLookupDetails>>}
   * @memberof AdminsInvitationSelectors
   */
  public static adminsEmailLookupDetailsMap: MemoizedSelector<AdminManagementState, Map<string, EmailLookupDetails>> = createSelector(
    AdminsInvitationSelectors.selectInvitedAdminsState,
    (state: AdminsInvitationState) => state.adminsEmailLookupDetailsMap,
  );

  /**
   * isLoadingPendingInvitationsList
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof AdminsInvitationSelectors
   */
  public static isLoadingPendingInvitationsList: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    AdminsInvitationSelectors.selectInvitedAdminsState,
    (state: AdminsInvitationState) => state.isLoadingPendingInvitationsList,
  );

  /**
   * pendingInvitationsList
   * @static
   * @type {MemoizedSelector<AdminManagementState, PendingInvitation[]>}
   * @memberof AdminsInvitationSelectors
   */
  public static pendingInvitationsList: MemoizedSelector<AdminManagementState, PendingInvitation[]> = createSelector(
    AdminsInvitationSelectors.selectInvitedAdminsState,
    (state: AdminsInvitationState) => state.pendingInvitationsList,
  );

  /**
   * selectedPendingInvitations
   * @static
   * @type {MemoizedSelector<AdminManagementState, PendingInvitation[]>}
   * @memberof AdminsInvitationSelectors
   */
  public static selectedPendingInvitations: MemoizedSelector<AdminManagementState, PendingInvitation[]> = createSelector(
    AdminsInvitationSelectors.selectInvitedAdminsState,
    (state: AdminsInvitationState) => state.selectedPendingInvitations,
  );

  /**
   * selectedInvitedAdminsUserNames
   * @static
   * @type {MemoizedSelector<AdminManagementState, string[]>}
   * @memberof AdminsInvitationSelectors
   */
  public static selectedInvitedAdminsUserNames: MemoizedSelector<AdminManagementState, string[]> = createSelector(
    AdminsInvitationSelectors.selectedPendingInvitations,
    (selectedPendingInvitations: PendingInvitation[]) => {
      return selectedPendingInvitations?.map((pendingInvitation: PendingInvitation) => {
        return pendingInvitation.userName;
      });
    },
  );

  /**
   * isNotifyingInvitedAdmins
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof AdminsInvitationSelectors
   */
  public static isNotifyingInvitedAdmins: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    AdminsInvitationSelectors.selectInvitedAdminsState,
    (state: AdminsInvitationState) => state.isNotifyingInvitedAdmins,
  );

  /**
   * isRevokingAdminInvitations
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof AdminsInvitationSelectors
   */
  public static isRevokingAdminInvitations: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    AdminsInvitationSelectors.selectInvitedAdminsState,
    (state: AdminsInvitationState) => state.isRevokingAdminInvitations,
  );

  /**
   * getSelectedAccountId
   * @static
   * @type {MemoizedSelector<AdminManagementState, string>}
   * @memberof AdminsInvitationSelectors
   */
  public static getSelectedAccountId: MemoizedSelector<AdminManagementState, string> = createSelector(
    AdminsInvitationSelectors.selectInvitedAdminsState,
    (state: AdminsInvitationState) => state.selectedAccountId,
  );
}
