/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { AdminUsersListResponse, AdminUsersState } from '@ws1c/admin-management/models/admin-users';

/**
 * Defines initial state for Admin Users
 *
 * @export
 * @type {AdminUsersState}
 */
export const initialAdminUsersState: AdminUsersState = {
  adminUsersListResponse: new AdminUsersListResponse(),
  isInvitingAdminUsers: false,
  isLoadingAdminUsersList: false,
  isLoadingSearchResults: false,
  searchedAdminUsers: [],
  selectedAdminUserListItem: null,
};
