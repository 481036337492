/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Action, createReducer, on } from '@ngrx/store';

import {
  AdminsInvitationState,
  EmailLookupDetails,
  PendingInvitation,
  UserExistenceStatus,
  UserExistenceStatusType,
} from '@ws1c/admin-management/models/admins-invitation';
import { getListWithAssignedServiceRoles } from '@ws1c/admin-management/utils';
import { AdminsInvitationActions } from './admins-invitation.actions';
import { initialAdminsInvitationState } from './admins-invitation.state';

/**
 * _reducer
 * @type {ActionReducer<AdminsInvitationState, Action>}
 */
const _reducer = createReducer(
  initialAdminsInvitationState,

  on(
    AdminsInvitationActions.getUsersExistenceStatus,
    (
      state: AdminsInvitationState,
      { adminsEmailLookupDetailsMap }: ReturnType<typeof AdminsInvitationActions.getUsersExistenceStatus>,
    ): AdminsInvitationState => ({
      ...state,
      adminsEmailLookupDetailsMap,
    }),
  ),

  on(
    AdminsInvitationActions.setUsersExistenceStatus,
    (
      state: AdminsInvitationState,
      { status, usersExistenceStatus }: ReturnType<typeof AdminsInvitationActions.setUsersExistenceStatus>,
    ): AdminsInvitationState => {
      const adminsEmailLookupDetailsMap: Map<string, EmailLookupDetails> = state.adminsEmailLookupDetailsMap;
      usersExistenceStatus.forEach(({ userName, userExistenceStatus }: UserExistenceStatus) => {
        adminsEmailLookupDetailsMap.set(
          userName,
          new EmailLookupDetails({
            ...adminsEmailLookupDetailsMap.get(userName),
            status,
            isAllowed: !!userExistenceStatus && userExistenceStatus !== UserExistenceStatusType.ORG_MEMBER,
            isUserExistenceStatusNonExist: userExistenceStatus === UserExistenceStatusType.NON_EXIST,
          }),
        );
      });
      return {
        ...state,
        adminsEmailLookupDetailsMap,
      };
    },
  ),

  on(
    AdminsInvitationActions.showLoadingPendingInvitationsList,
    AdminsInvitationActions.loadPendingInvitationsList,
    (state: AdminsInvitationState): AdminsInvitationState => ({
      ...state,
      isLoadingPendingInvitationsList: true,
      selectedPendingInvitations: null,
    }),
  ),

  on(
    AdminsInvitationActions.loadPendingInvitationsListSuccess,
    (
      state: AdminsInvitationState,
      {
        pendingInvitationsList,
        orgGroupList,
        serviceRoleById,
      }: ReturnType<typeof AdminsInvitationActions.loadPendingInvitationsListSuccess>,
    ): AdminsInvitationState => {
      pendingInvitationsList = getListWithAssignedServiceRoles(
        pendingInvitationsList,
        orgGroupList,
        serviceRoleById,
      ) as PendingInvitation[];
      return {
        ...state,
        pendingInvitationsList,
        isLoadingPendingInvitationsList: false,
      };
    },
  ),

  on(
    AdminsInvitationActions.loadPendingInvitationsListFailure,
    (state: AdminsInvitationState): AdminsInvitationState => ({
      ...state,
      isLoadingPendingInvitationsList: false,
    }),
  ),

  on(
    AdminsInvitationActions.resetAdminsEmailLookupDetails,
    (state: AdminsInvitationState): AdminsInvitationState => ({
      ...state,
      adminsEmailLookupDetailsMap: new Map<string, EmailLookupDetails>(),
    }),
  ),

  on(
    AdminsInvitationActions.setSelectedPendingInvitations,
    (
      state: AdminsInvitationState,
      { selectedPendingInvitations }: ReturnType<typeof AdminsInvitationActions.setSelectedPendingInvitations>,
    ): AdminsInvitationState => ({
      ...state,
      selectedPendingInvitations,
    }),
  ),

  on(
    AdminsInvitationActions.notifyInvitedAdmin,
    (state: AdminsInvitationState): AdminsInvitationState => ({
      ...state,
      isNotifyingInvitedAdmins: true,
    }),
  ),

  on(
    AdminsInvitationActions.notifyInvitedAdminSuccess,
    AdminsInvitationActions.notifyInvitedAdminFailure,
    (state: AdminsInvitationState): AdminsInvitationState => ({
      ...state,
      isNotifyingInvitedAdmins: false,
    }),
  ),

  on(
    AdminsInvitationActions.revokeAdminInvitation,
    (state: AdminsInvitationState): AdminsInvitationState => ({
      ...state,
      isRevokingAdminInvitations: true,
    }),
  ),

  on(
    AdminsInvitationActions.revokeAdminInvitationSuccess,
    AdminsInvitationActions.revokeAdminInvitationFailure,
    (state: AdminsInvitationState): AdminsInvitationState => ({
      ...state,
      isRevokingAdminInvitations: false,
    }),
  ),

  on(
    AdminsInvitationActions.setSelectedAccountId,
    (
      state: AdminsInvitationState,
      { selectedAccountId }: ReturnType<typeof AdminsInvitationActions.setSelectedAccountId>,
    ): AdminsInvitationState => ({
      ...state,
      selectedAccountId,
    }),
  ),
);

/**
 * Creates and returns AdminsInvitationState
 * @export
 * @param {AdminsInvitationState} state - Holds state object of type AdminsInvitationState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {AdminsInvitationState}
 */
export function adminsInvitationReducer(state: AdminsInvitationState, action: Action): AdminsInvitationState {
  return _reducer(state, action);
}
