/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { SearchTerm } from '@ws1c/intelligence-models';

/**
 * Admin Management Paged Request
 *
 * @export
 * @class AdminManagementPagedRequest
 */
@Serializable
export class AdminManagementPagedRequest {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty({ name: 'search_terms', cls: SearchTerm })
  public searchTerms?: SearchTerm[] = undefined;

  /**
   * Initializes instance of AdminManagementPagedRequest
   * @param {Array<Partial<AdminManagementPagedRequest>>} args - object initialization arguments
   * @memberof AdminManagementPagedRequest
   */
  constructor(...args: Array<Partial<AdminManagementPagedRequest>>) {
    Object.assign(this, ...args);
  }
}
