/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { UserExistenceStatus } from './user-existence-status.model';

/**
 * UsersExistenceStatusResponse
 *
 * @export
 * @class UsersExistenceStatusResponse
 */
@Serializable
export class UsersExistenceStatusResponse {
  @JsonProperty({ name: 'data', cls: UserExistenceStatus })
  public data: UserExistenceStatus[] = undefined;
}
