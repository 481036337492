/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AdminRoles } from '@ws1c/admin-management/models/admin-roles.model';

/**
 * Pending Invitation Model
 *
 * @export
 * @class PendingInvitation
 * @extends {AdminRoles}
 */
@Serializable
export class PendingInvitation extends AdminRoles {
  @JsonProperty('username')
  public userName: string = undefined;

  /**
   * Creates an instance of PendingInvitation
   * @param {Array<Partial<PendingInvitation>>} args - Initialization arguments for PendingInvitation
   * @memberof PendingInvitation
   */
  constructor(...args: Array<Partial<PendingInvitation>>) {
    super();
    Object.assign(this, ...args);
  }
}
