/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Platform Role
 *
 * @export
 * @class PlatformRole
 */
@Serializable
export class PlatformRole {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('display_name')
  public displayName: string = undefined;

  @JsonProperty('hidden')
  public isHidden: boolean = undefined;

  @JsonProperty('membership_type')
  public membershipType: string = undefined;

  public isSelected: boolean;

  /**
   * Initializes instance of PlatformRole
   * @param {Array<Partial<PlatformRole>>} args - object initialization arguments
   * @memberof PlatformRole
   */
  constructor(...args: Array<Partial<PlatformRole>>) {
    Object.assign(this, ...args);
  }
}
