/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AdminManagementState, AdminUser, AdminUserListItem, AdminUsersListResponse, AdminUsersState } from '@ws1c/admin-management/models';
import { selectAdminManagementState } from '@ws1c/admin-management/store/admin-management.selector';

/**
 * AdminUsersSelectors
 * @export
 * @class AdminUsersSelectors
 */
export class AdminUsersSelectors {
  /**
   * Selects the Admin Users state slice from Admin Management store
   * @static
   * @type {MemoizedSelector<AdminManagementState, AdminUsersState>}
   * @memberof AdminUsersSelectors
   */
  public static selectAdminUsersState: MemoizedSelector<AdminManagementState, AdminUsersState> = createSelector(
    selectAdminManagementState,
    (state: AdminManagementState) => state.adminUsers,
  );

  /**
   * isInvitingAdminUsers
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof AdminUsersSelectors
   */
  public static isInvitingAdminUsers: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    AdminUsersSelectors.selectAdminUsersState,
    (state: AdminUsersState) => state.isInvitingAdminUsers,
  );

  /**
   * isLoadingSearchResults
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof AdminUsersSelectors
   */
  public static isLoadingSearchResults: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    AdminUsersSelectors.selectAdminUsersState,
    (state: AdminUsersState) => state.isLoadingSearchResults,
  );

  /**
   * searchedAdminUsers
   * @static
   * @type {MemoizedSelector<AdminManagementState, AdminUser[]>}
   * @memberof AdminUsersSelectors
   */
  public static searchedAdminUsers: MemoizedSelector<AdminManagementState, AdminUser[]> = createSelector(
    AdminUsersSelectors.selectAdminUsersState,
    (state: AdminUsersState) => state.searchedAdminUsers,
  );

  /**
   * adminUsersListResponse
   * @static
   * @type {MemoizedSelector<AdminManagementState, AdminUsersListResponse>}
   * @memberof AdminUsersSelectors
   */
  public static adminUsersListResponse: MemoizedSelector<AdminManagementState, AdminUsersListResponse> = createSelector(
    AdminUsersSelectors.selectAdminUsersState,
    (state: AdminUsersState) => state.adminUsersListResponse,
  );

  /**
   * isLoadingAdminUsersList
   * @static
   * @type {MemoizedSelector<AdminManagementState, boolean>}
   * @memberof AdminUsersSelectors
   */
  public static isLoadingAdminUsersList: MemoizedSelector<AdminManagementState, boolean> = createSelector(
    AdminUsersSelectors.selectAdminUsersState,
    (state: AdminUsersState) => state.isLoadingAdminUsersList,
  );

  /**
   * selectedAdminUserListItem
   * @static
   * @type {MemoizedSelector<AdminManagementState, AdminUserListItem>}
   * @memberof AdminUsersSelectors
   */
  public static selectedAdminUserListItem: MemoizedSelector<AdminManagementState, AdminUserListItem> = createSelector(
    AdminUsersSelectors.selectAdminUsersState,
    (state: AdminUsersState) => state.selectedAdminUserListItem,
  );
}
