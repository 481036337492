/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

import { EmailLookupDetails, IntegrationOrgGroup, PendingInvitation, UserExistenceStatus } from '@ws1c/admin-management/models';
import { AccountRole, LOAD_STATE } from '@ws1c/intelligence-models';

/**
 * Admins Invitation Actions
 *
 * @export
 * @class AdminsInvitationActions
 */
export class AdminsInvitationActions {
  public static readonly CATEGORY = '[ADMINS_INVITATION]';

  public static readonly getUsersExistenceStatus = createAction(
    `${AdminsInvitationActions.CATEGORY} GET_USERS_EXISTENCE_STATUS`,
    props<{ adminsEmailLookupDetailsMap: Map<string, EmailLookupDetails> }>(),
  );

  public static goToAdminsInvitationListPage = createAction(`${AdminsInvitationActions.CATEGORY} GO_TO_ADMINS_INVITATION_LIST_PAGE`);

  public static readonly setUsersExistenceStatus = createAction(
    `${AdminsInvitationActions.CATEGORY} SET_USERS_EXISTENCE_STATUS`,
    props<{
      status: LOAD_STATE;
      usersExistenceStatus: UserExistenceStatus[];
    }>(),
  );

  public static readonly inviteAdmin = createAction(`${AdminsInvitationActions.CATEGORY} INVITE_ADMIN`);

  public static readonly notifyInvitedAdmin = createAction(`${AdminsInvitationActions.CATEGORY} NOTIFY_INVITED_ADMIN`);

  public static readonly notifyInvitedAdminSuccess = createAction(`${AdminsInvitationActions.CATEGORY} NOTIFY_INVITED_ADMIN_SUCCESS`);

  public static readonly notifyInvitedAdminFailure = createAction(`${AdminsInvitationActions.CATEGORY} NOTIFY_INVITED_ADMIN_FAILURE`);

  public static readonly revokeAdminInvitation = createAction(`${AdminsInvitationActions.CATEGORY} REVOKE_ADMIN_INVITATION`);

  public static readonly revokeAdminInvitationSuccess = createAction(`${AdminsInvitationActions.CATEGORY} REVOKE_ADMIN_INVITATION_SUCCESS`);

  public static readonly revokeAdminInvitationFailure = createAction(`${AdminsInvitationActions.CATEGORY} REVOKE_ADMIN_INVITATION_FAILURE`);

  public static readonly showLoadingPendingInvitationsList = createAction(
    `${AdminsInvitationActions.CATEGORY} SHOW_LOADING_PENDING_INVITATIONS_LIST`,
  );

  public static loadPendingInvitationsList = createAction(`${AdminsInvitationActions.CATEGORY} LOAD_PENDING_INVITATIONS_LIST`);

  public static loadPendingInvitationsListSuccess = createAction(
    `${AdminsInvitationActions.CATEGORY} LOAD_PENDING_INVITATIONS_LIST_SUCCESS`,
    props<{
      pendingInvitationsList: PendingInvitation[];
      orgGroupList: IntegrationOrgGroup[];
      serviceRoleById: Map<string, AccountRole>;
    }>(),
  );

  public static loadPendingInvitationsListFailure = createAction(
    `${AdminsInvitationActions.CATEGORY} LOAD_PENDING_INVITATIONS_LIST_FAILURE`,
  );

  public static readonly resetAdminsEmailLookupDetails = createAction(
    `${AdminsInvitationActions.CATEGORY} RESET_ADMINS_EMAIL_LOOKUP_DETAILS`,
  );

  public static readonly setSelectedPendingInvitations = createAction(
    `${AdminsInvitationActions.CATEGORY} SET_SELECTED_PENDING_INVITATIONS`,
    props<{ selectedPendingInvitations: PendingInvitation[] }>(),
  );

  public static readonly setSelectedAccountId = createAction(
    `${AdminsInvitationActions.CATEGORY} SET_SELECTED_ACCOUNT_ID`,
    props<{ selectedAccountId: string }>(),
  );
}
