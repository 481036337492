/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Admin Group
 *
 * @export
 * @class AdminGroup
 */
@Serializable
export class AdminGroup {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('directory_group_id')
  public directoryGroupId: string = undefined;

  @JsonProperty('display_name')
  public displayName: string = undefined;

  @JsonProperty('users_count')
  public usersCount: number = undefined;

  @JsonProperty('csp_org_role_present')
  public hasCspOrgRole: boolean = undefined;

  @JsonProperty('ws1c_service_role_present')
  public hasWSOneCloudRole: boolean = undefined;

  /**
   * Initializes instance of AdminGroup
   * @param {Array<Partial<AdminGroup>>} args - object initialization arguments
   * @memberof AdminGroup
   */
  constructor(...args: Array<Partial<AdminGroup>>) {
    return Object.assign(this, ...args);
  }
}
