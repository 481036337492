/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { ActionReducerMap } from '@ngrx/store';

import { AdminManagementState } from '@ws1c/admin-management/models';
import { coreAppReducer } from '@ws1c/intelligence-core';
import { adminGroupsReducer } from './admin-groups/admin-groups.reducer';
import { adminUsersReducer } from './admin-users/admin-users.reducer';
import { adminsInvitationReducer } from './admins-invitation/admins-invitation.reducer';
import { rolesReducer } from './roles/roles.reducer';

export const adminManagementReducerMap: ActionReducerMap<AdminManagementState> = {
  ...coreAppReducer,
  adminUsers: adminUsersReducer,
  adminGroups: adminGroupsReducer,
  invitedAdmins: adminsInvitationReducer,
  roles: rolesReducer,
};
