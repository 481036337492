/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { gql } from 'apollo-angular';

export const ACCOUNTS_BY_ROLES_FETCH = gql`
  query AccountDetailsByRole($pagedSearchRequestInput: PagedSearchRequestInput!, $roleId: UUID!) {
    accountWithFirstAndLastName(search_request: $pagedSearchRequestInput, role_id: $roleId) {
      paged_response {
        offset
        page_size
        total_count
      }
      account_details {
        id
        display_name
        email
        first_name
        last_name
        data_access_policies {
          id
          name
          description
          integration {
            name
          }
          filter_configuration_type
          org_id
          enabled
          created_at
          modified_at
          created_by
          modified_by
        }
        roles {
          id
          label
          name
          scopes {
            id
            name
          }
          created_at
          created_by
        }
      }
    }
  }
`;
