/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Account Migration Status Request Model
 *
 * @export
 * @class AccountMigrationStatusRequest
 */
@Serializable
export class AccountMigrationStatusRequest {
  @JsonProperty('accountId')
  public accountId: string = undefined;

  @JsonProperty('email')
  public email: string = undefined;

  @JsonProperty('csp_migration_status')
  public cspMigrationStatus: string = undefined;

  /**
   * Creates an instance of AccountMigrationStatusRequest
   * @param {Array<Partial<AccountMigrationStatusRequest>>} args - Initialization arguments for AccountMigrationStatusRequest
   * @memberof AccountMigrationStatusRequest
   */
  constructor(...args: Array<Partial<AccountMigrationStatusRequest>>) {
    Object.assign(this, ...args);
  }
}
