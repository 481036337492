/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AdminGroup } from './admin-group.model';

/**
 * Admin Groups Search Response
 *
 * @export
 * @class AdminGroupsSearchResponse
 */
@Serializable
export class AdminGroupsSearchResponse {
  @JsonProperty({ name: 'data', cls: AdminGroup, excludeToJson: true })
  public data: AdminGroup[] = undefined;
}
