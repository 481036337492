/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { createAction } from '@ngrx/store';

/**
 * AdminManagementCommonActions
 *
 * @export
 * @class AdminManagementCommonActions
 */
export class AdminManagementCommonActions {
  public static readonly CATEGORY = '[ADMIN_MANAGEMENT_COMMON]';

  public static exitAdminsGroupsAddEditFlow = createAction(`${AdminManagementCommonActions.CATEGORY} EXIT_ADMINS_GROUPS_ADD_EDIT_FLOW`);
}
