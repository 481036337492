/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createFeatureSelector, MemoizedSelector } from '@ngrx/store';

import { ADMIN_MANAGEMENT_STORE_KEY } from '@ws1c/admin-management/const/admin-management-store-key';
import { AdminManagementState } from '@ws1c/admin-management/models/admin-management-state.interface';

export const selectAdminManagementState: MemoizedSelector<AdminManagementState, AdminManagementState> =
  createFeatureSelector<AdminManagementState>(ADMIN_MANAGEMENT_STORE_KEY);
