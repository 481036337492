/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AdminManagementPagedRequest, ExternalServiceRolesRequest, IntegrationOrgGroup, PlatformRole } from '@ws1c/admin-management/models';
import { Endpoint, GraphqlService, HttpService } from '@ws1c/intelligence-common';
import {
  AccountsSearchResponse,
  BulkUpdateRoleAssignmentsRequest,
  CreateCustomRoleRequest,
  GenericSearchRequest,
  RolesSearchResponse,
  ScopeSetSearchResponse,
} from '@ws1c/intelligence-models';
import { ACCOUNTS_BY_ROLES_FETCH } from './accounts-by-role.graphql';
import { ROLES_FETCH } from './roles.graphql';

/**
 * RolesService
 * @export
 * @class RolesService
 */
@Injectable()
export class RolesService {
  /**
   * Creates an instance of RolesService.
   * @param {HttpService} httpService
   * @param {GraphqlService} graphqlService
   * @memberof RolesService
   */
  constructor(
    private httpService: HttpService,
    private graphqlService: GraphqlService,
  ) {}

  /**
   * Retrieves platform roles
   * @returns {Observable<PlatformRole[]>}
   * @memberof RolesService
   */
  public getPlatformRoles(): Observable<PlatformRole[]> {
    return this.httpService.get(Endpoint.ADMIN_PLATFORM_ROLES).pipe(
      map((response: GenericObject) => {
        return response.data.map((platformRole: GenericObject) => deserialize(PlatformRole, platformRole));
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Retrieves service roles for a given integration id
   * @param {ExternalServiceRolesRequest} externalServiceRolesRequest - holds request object
   * @param {IntegrationOrgGroup} orgGroup - integration org group for which roles needs to be fetched
   * @returns {Observable<RolesSearchResponse>}
   * @memberof RolesService
   */
  public getRolesByIntegrationId(
    externalServiceRolesRequest: ExternalServiceRolesRequest,
    orgGroup: IntegrationOrgGroup,
  ): Observable<RolesSearchResponse> {
    const { pagedRequest, integrationDetails } = externalServiceRolesRequest;
    return this.httpService
      .post(
        Endpoint.ROLES_BY_INTEGRATION_ID(integrationDetails.id, orgGroup?.tenantUuid),
        new AdminManagementPagedRequest({ ...pagedRequest }),
      )
      .pipe(
        map((response: GenericObject) => deserialize(RolesSearchResponse, response.data)),
        catchError(requestErrorHandler),
      );
  }

  /**
   * Retrieves all service roles for a given integration id
   * @param {ExternalServiceRolesRequest} externalServiceRolesRequest - holds request object
   * @returns {Observable<RolesSearchResponse>}
   * @memberof RolesService
   */
  public getAllRolesByIntegrationId(externalServiceRolesRequest: ExternalServiceRolesRequest): Observable<RolesSearchResponse> {
    const { pagedRequest, integrationDetails } = externalServiceRolesRequest;
    return this.httpService
      .post(
        Endpoint.ALL_ROLES_BY_INTEGRATION_ID(integrationDetails.id, integrationDetails.tenantUuid, true),
        new AdminManagementPagedRequest({ ...pagedRequest }),
      )
      .pipe(
        map((response: GenericObject) => deserialize(RolesSearchResponse, response.data)),
        catchError(requestErrorHandler),
      );
  }

  /**
   * Retrieves org group tenant hierarchy by integration id
   * @param {string} integrationId - integration id
   * @returns {Observable<IntegrationOrgGroup[]>}
   * @memberof RolesService
   */
  public getOrgGroupListByIntegrationId(integrationId: string): Observable<IntegrationOrgGroup[]> {
    return this.httpService.get(Endpoint.ORG_GROUP_TENANT_HIERARCHY(integrationId)).pipe(
      map((response: GenericObject) => {
        return response.data.map((integrationOrgGroupDetails: GenericObject) =>
          deserialize(IntegrationOrgGroup, integrationOrgGroupDetails),
        );
      }),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getScopeSet
   * @param {GenericSearchRequest} request
   * @returns {Observable<ScopeSetSearchResponse>}
   * @memberof RolesService
   */
  public getScopeSet(request: GenericSearchRequest): Observable<ScopeSetSearchResponse> {
    return this.httpService.post(Endpoint.SCOPESET_SEARCH, request).pipe(
      map((response: GenericObject) => deserialize(ScopeSetSearchResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * createCustomRole
   * @param {CreateCustomRoleRequest} request
   * @returns {Observable<boolean>}
   * @memberof RolesService
   */
  public createCustomRole(request: CreateCustomRoleRequest): Observable<boolean> {
    return this.httpService.post(Endpoint.IAM_ROLES, request).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * editCustomRole
   * @param {CreateCustomRoleRequest} request
   * @returns {Observable<boolean>}
   * @memberof RolesService
   */
  public editCustomRole(request: CreateCustomRoleRequest): Observable<boolean> {
    return this.httpService.put(Endpoint.IAM_ROLES, request).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * deleteCustomRole
   * @param {string[]} identifiers
   * @returns {Observable<boolean>}
   * @memberof RolesService
   */
  public deleteCustomRole(identifiers: string[]): Observable<boolean> {
    return this.httpService
      .delete(Endpoint.IAM_ROLES, {
        body: { identifiers },
      })
      .pipe(
        map(() => true),
        catchError(requestErrorHandler),
      );
  }

  /**
   * assignAccounts
   * @param {BulkUpdateRoleAssignmentsRequest} request
   * @returns {Observable<boolean>}
   * @memberof RolesService
   */
  public assignAccounts(request: BulkUpdateRoleAssignmentsRequest): Observable<boolean> {
    return this.httpService.put(Endpoint.IAM_ROLES_ASSIGN_ACCOUNTS, request).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getAccountAssignmentsByRoleId
   * @param {GenericSearchRequest} request
   * @param {string} roleId
   * @returns {Observable<AccountsSearchResponse>}
   * @memberof RolesService
   */
  public getAccountAssignmentsByRoleId(request: GenericSearchRequest, roleId: string): Observable<AccountsSearchResponse> {
    return this.graphqlService
      .query(ACCOUNTS_BY_ROLES_FETCH, {
        pagedSearchRequestInput: request,
        roleId,
      })
      .pipe(
        map((response: GenericObject) => {
          const updatedResponse = {
            ...response?.accountWithFirstAndLastName.paged_response,
            results: response?.accountWithFirstAndLastName.account_details?.map((accountDetails: GenericObject) => accountDetails),
          };
          return deserialize(AccountsSearchResponse, updatedResponse);
        }),
        catchError(requestErrorHandler),
      );
  }

  /**
   *
   * @param {GenericSearchRequest} request
   * @returns {Observable<RolesSearchResponse>}
   * @memberof RolesService
   */
  public getRoles(request: GenericSearchRequest): Observable<RolesSearchResponse> {
    return this.graphqlService
      .query(ROLES_FETCH, {
        genericPagedRequestInput: request,
      })
      .pipe(
        map((response: GenericObject) => {
          const updatedResponse = {
            ...response?.retrievePagedRoleViews.paged_response,
            results: response?.retrievePagedRoleViews?.role_view_details?.map((roleDetails: GenericObject) => {
              return {
                ...roleDetails?.role_view_info,
                assigned_accounts_count: roleDetails?.assigned_accounts_count,
              };
            }),
          };
          return deserialize(RolesSearchResponse, updatedResponse);
        }),
        catchError(requestErrorHandler),
      );
  }
}
