/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest, WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import { ScopeSetAccessOption } from '@ws1c/admin-management/const';
import { ExternalServiceRolesRequest, IntegrationOrgGroup, PlatformRole, ServiceRole } from '@ws1c/admin-management/models';
import {
  Account,
  AccountRole,
  AccountsSearchResponse,
  GenericSearchRequest,
  IntegrationDetails,
  RolesSearchResponse,
  ScopeSetSearchResponse,
} from '@ws1c/intelligence-models';

/**
 * Roles Actions
 *
 * @export
 * @class RolesActions
 */
export class RolesActions {
  public static readonly CATEGORY = '[ROLES]';
  private static type = (actionName: string): string => `${RolesActions.CATEGORY} ${actionName}`;

  public static readonly getExternalServiceRoles = createAction(
    RolesActions.type('GET_EXTERNAL_SERVICE_ROLES'),
    props<{ externalServiceRolesRequest: ExternalServiceRolesRequest }>(),
  );

  public static readonly getExternalServiceRolesSuccess = createAction(
    RolesActions.type('GET_EXTERNAL_SERVICE_ROLES_SUCCESS'),
    props<{
      integrationId: string;
      externalServiceRolesResponse: RolesSearchResponse;
    }>(),
  );

  public static readonly getExternalServiceRolesFailure = createAction(
    RolesActions.type('GET_EXTERNAL_SERVICE_ROLES_FAILURE'),
    props<{ integrationId: string }>(),
  );

  public static readonly getIntelligenceRoles = createAction(RolesActions.type('GET_INTELLIGENCE_ROLES'));
  public static readonly searchIntelligenceRoles = createAction(RolesActions.type('SEARCH_INTELLIGENCE_ROLES'));

  public static readonly getIntelligenceRolesSuccess = createAction(
    RolesActions.type('GET_INTELLIGENCE_ROLES_SUCCESS'),
    props<{ intelligenceRolesResponse: RolesSearchResponse }>(),
  );

  public static readonly getIntelligenceRolesFailure = createAction(RolesActions.type('GET_INTELLIGENCE_ROLES_FAILURE'));

  public static readonly getPlatformRoles = createAction(RolesActions.type('GET_PLATFORM_ROLES'));

  public static readonly getPlatformRolesSuccess = createAction(
    RolesActions.type('GET_PLATFORM_ROLES_SUCCESS'),
    props<{ platformRoles: PlatformRole[] }>(),
  );

  public static readonly getPlatformRolesFailure = createAction(RolesActions.type('GET_PLATFORM_ROLES_FAILURE'));

  public static readonly resetSelectedRoles = createAction(RolesActions.type('RESET_SELECTED_ROLES'));

  public static readonly setRolesConfirmModalOpenState = createAction(
    RolesActions.type('SET_ROLES_CONFIRM_MODAL_OPEN_STATE'),
    props<{ isOpen: boolean }>(),
  );

  public static readonly setSelectedExternalServiceRoles = createAction(
    RolesActions.type('SET_SELECTED_EXTERNAL_SERVICE_ROLES'),
    props<{
      integrationId: string;
      tenantUuid: string;
      selectedExternalServiceRoles: AccountRole[];
    }>(),
  );

  public static readonly setSelectedIntelligenceRoles = createAction(
    RolesActions.type('SET_SELECTED_INTELLIGENCE_ROLES'),
    props<{ selectedIntelligenceRoles: AccountRole[] }>(),
  );

  public static readonly getOrgGroupListByIntegrationId = createAction(
    RolesActions.type('GET_ORG_GROUP_LIST_BY_INTEGRATION_ID'),
    props<{ integrationId: string }>(),
  );

  public static readonly getOrgGroupListByIntegrationIdSuccess = createAction(
    RolesActions.type('GET_ORG_GROUP_LIST_BY_INTEGRATION_ID_SUCCESS'),
    props<{
      integrationId: string;
      orgGroupList: IntegrationOrgGroup[];
    }>(),
  );

  public static readonly getOrgGroupListByIntegrationIdFailure = createAction(
    RolesActions.type('GET_ORG_GROUP_LIST_BY_INTEGRATION_ID_FAILURE'),
    props<{ integrationId: string }>(),
  );

  public static readonly getOrgGroupListForServiceTypes = createAction(
    RolesActions.type('GET_ORG_GROUP_LIST_FOR_SERVICE_TYPES'),
    props<{ serviceTypes: string[] }>(),
  );

  public static readonly getOrgGroupListForServiceType = createAction(
    RolesActions.type('GET_ORG_GROUP_LIST_FOR_SERVICE_TYPE'),
    props<{ integrationId: string }>(),
  );

  public static readonly setSelectedOrgGroupByIntegrationId = createAction(
    RolesActions.type('SET_SELECTED_ORG_GROUP_BY_INTEGRATION_ID'),
    props<{
      integrationId: string;
      selectedOrgGroup: IntegrationOrgGroup;
    }>(),
  );

  public static readonly setSelectedTenant = createAction(
    RolesActions.type('SET_SELECTED_TENANT'),
    props<{
      selectedTenant: IntegrationDetails;
    }>(),
  );

  public static readonly setSelectedRoles = createAction(
    RolesActions.type('SET_SELECTED_ROLES'),
    props<{
      platformRoles: PlatformRole[];
      serviceRoles: ServiceRole[];
    }>(),
  );

  public static readonly setSelectedRolesForAllExternalServices = createAction(
    RolesActions.type('SET_SELECTED_ROLES_FOR_ALL_EXTERNAL_SERVICES'),
    props<{ selectedExternalServiceRolesMap: Record<string, Record<string, AccountRole[]>> }>(),
  );

  public static readonly getAllRolesForExternalServices = createAction(RolesActions.type('GET_ALL_ROLES_FOR_EXTERNAL_SERVICES'));

  public static readonly getAllRolesForExternalService = createAction(
    RolesActions.type('GET_ALL_ROLES_FOR_EXTERNAL_SERVICE'),
    props<{
      externalServiceRolesRequest: ExternalServiceRolesRequest;
    }>(),
  );

  public static readonly setDeactivateModalOpenState = createAction(
    RolesActions.type('SET_DEACTIVATE_MODAL_OPEN_STATE'),
    props<{ isOpen: boolean }>(),
  );

  public static readonly setSelectedRole = createAction(RolesActions.type('SET_SELECTED_ROLE'), props<{ role: AccountRole }>());

  public static readonly getScopeSet = createAction(RolesActions.type('GET_SCOPE_SET'));

  public static readonly getScopeSetSuccess = createAction(
    RolesActions.type('GET_SCOPE_SET_SUCCESS'),
    props<{ response: ScopeSetSearchResponse }>(),
  );

  public static readonly getScopeSetFailure = createAction(RolesActions.type('GET_SCOPE_SET_FAILURE'), props<{ error: WebError }>());

  public static readonly openCreateCustomRoleModal = createAction(RolesActions.type('OPEN_CREATE_CUSTOM_ROLE_MODAL'));

  public static readonly closeCreateCustomRoleModal = createAction(RolesActions.type('CLOSE_CREATE_CUSTOM_ROLE_MODAL'));

  public static readonly setScopeSetAccess = createAction(
    RolesActions.type('SET_SCOPE_SET_ACCESS'),
    props<{ scopeSetId: string; scopeSetAccessOption: ScopeSetAccessOption }>(),
  );

  public static readonly setScopeSetAccessById = createAction(
    RolesActions.type('SET_SCOPE_SET_ACCESS_BY_ID'),
    props<{ scopeSetAccessById: Record<string, ScopeSetAccessOption> }>(),
  );

  public static readonly saveCustomRole = createAction(
    RolesActions.type('SAVE_CUSTOM_ROLE'),
    props<{ name: string; description: string }>(),
  );

  public static readonly saveCustomRoleSuccess = createAction(RolesActions.type('SAVE_CUSTOM_ROLE_SUCCESS'));

  public static readonly saveCustomRoleFailure = createAction(RolesActions.type('SAVE_CUSTOM_ROLE_FAILURE'), props<{ error: WebError }>());

  public static readonly editCustomRole = createAction(RolesActions.type('EDIT_CUSTOM_ROLE_PERMISSIONS'));

  public static readonly saveEditCustomRole = createAction(
    RolesActions.type('SAVE_EDIT_CUSTOM_ROLE_PERMISSIONS'),
    props<{ name: string; description: string; id: string }>(),
  );

  public static readonly saveEditCustomRoleSuccess = createAction(RolesActions.type('SAVE_EDIT_CUSTOM_ROLE_PERMISSIONS_SUCCESS'));

  public static readonly saveEditCustomRoleFailure = createAction(
    RolesActions.type('SAVE_EDIT_CUSTOM_ROLE_PERMISSIONS_FAILURE'),
    props<{ error: WebError }>(),
  );

  public static readonly deleteCustomRole = createAction(RolesActions.type('DELETE_CUSTOM_ROLE'), props<{ role: AccountRole }>());

  public static readonly deleteCustomRoleSuccess = createAction(RolesActions.type('DELETE_CUSTOM_ROLE_SUCCESS'));

  public static readonly deleteCustomRoleFailure = createAction(
    RolesActions.type('DELETE_CUSTOM_ROLE_FAILURE'),
    props<{ error: WebError }>(),
  );

  public static readonly confirmDeleteCustomRole = createAction(RolesActions.type('CONFIRM_DELETE_CUSTOM_ROLE'));

  public static readonly cancelDeleteCustomRole = createAction(RolesActions.type('CANCEL_DELETE_CUSTOM_ROLE'));

  public static readonly getAccountsByRoleId = createAction(RolesActions.type('GET_ACCOUNTS_BY_ROLE_ID'), props<{ roleId: string }>());

  public static readonly getAccountsByRoleIdSuccess = createAction(
    RolesActions.type('GET_ACCOUNTS_BY_ROLE_ID_SUCCESS'),
    props<{ accountsSearchResponse: AccountsSearchResponse }>(),
  );

  public static readonly getAccountsByRoleIdFailure = createAction(
    RolesActions.type('GET_ACCOUNTS_BY_ROLE_ID_FAILURE'),
    props<{ error: WebError }>(),
  );

  public static readonly selectAccountsToUnassign = createAction(
    RolesActions.type('SELECT_ACCOUNTS_TO_UNASSIGN'),
    props<{ accounts: Account[] }>(),
  );

  public static readonly selectUsersToAssign = createAction(RolesActions.type('SELECT_USERS_TO_ASSIGN'), props<{ accounts: Account[] }>());

  public static readonly unassignAccounts = createAction(RolesActions.type('UNASSIGN_ACCOUNTS'));

  public static readonly cancelUnassignAccounts = createAction(RolesActions.type('CANCEL_UNASSIGN_ACCOUNTS'));

  public static readonly confirmUnassignAccounts = createAction(RolesActions.type('CONFIRM_UNASSIGN_ACCOUNTS'));

  public static readonly unassignAccountsSuccess = createAction(
    RolesActions.type('UNASSIGN_ACCOUNTS_SUCCESS'),
    props<{ roleId: string }>(),
  );

  public static readonly unassignAccountsFailure = createAction(
    RolesActions.type('UNASSIGN_ACCOUNTS_FAILURE'),
    props<{ error: WebError }>(),
  );

  public static readonly assignUsers = createAction(RolesActions.type('ASSIGN_USERS'));

  public static readonly cancelAssignUsers = createAction(RolesActions.type('CANCEL_ASSIGN_USERS'));

  public static readonly confirmAssignUsers = createAction(RolesActions.type('CONFIRM_ASSIGN_USERS'));

  public static readonly assignUsersSuccess = createAction(RolesActions.type('ASSIGN_USERS_SUCCESS'), props<{ roleId: string }>());

  public static readonly assignUsersFailure = createAction(RolesActions.type('ASSIGN_USERS_FAILURE'), props<{ error: WebError }>());

  public static readonly setAssignUsersListRequest = createAction(
    RolesActions.type('SET_ASSIGN_USERS_LIST_REQUEST'),
    props<{ searchRequest: GenericSearchRequest }>(),
  );

  public static readonly changeUsersPagination = createAction(
    RolesActions.type('CHANGE_USERS_PAGINATION'),
    props<{ pagedRequest: PagedRequest; roleId: string }>(),
  );

  public static readonly changeRolesPagination = createAction(
    RolesActions.type('CHANGE_ROLES_PAGINATION'),
    props<{ pagedRequest: PagedRequest }>(),
  );

  public static readonly setAccountSearchTerm = createAction(RolesActions.type('SET_ACCOUNT_SEARCH_TERM'), props<{ query: string }>());
}
