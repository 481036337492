/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  AccountMigrationStatusRequest,
  AdminGroupsUsersListRequest,
  AdminUserListItem,
  AdminUsersInviteRequest,
  AdminUsersListResponse,
  AdminUsersSearchResponse,
} from '@ws1c/admin-management/models';
import { Endpoint, GenericResponse, HttpService } from '@ws1c/intelligence-common';

/**
 * Admin Users Service
 *
 * @export
 * @class AdminUsersService
 */
@Injectable()
export class AdminUsersService {
  /**
   * Initializes instance of AdminUsersService
   * @param {HttpService} httpService - Instance of HttpService
   * @memberof AdminUsersService
   */
  constructor(private httpService: HttpService) {}

  /**
   * Method to search admin users for a given search term
   * @param {string} searchTerm - Search term
   * @returns {Observable<AdminUsersSearchResponse>}
   * @memberof AdminUsersService
   */
  public searchAdminUsers(searchTerm: string): Observable<AdminUsersSearchResponse> {
    return this.httpService.post(Endpoint.USERS_SEARCH(searchTerm), null).pipe(
      map((response: GenericResponse) => deserialize(AdminUsersSearchResponse, response)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Method to invite admin users with selected roles
   * @param {AdminUsersInviteRequest} adminUsersInviteRequest - Holds detail users with selected roles list
   * @returns {Observable<boolean>}
   * @memberof AdminUsersService
   */
  public inviteAdminUsers(adminUsersInviteRequest: AdminUsersInviteRequest): Observable<boolean> {
    return this.httpService.post(Endpoint.USERS_INVITATIONS, adminUsersInviteRequest).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Method to retrieve admin users list based on given request
   * @param {AdminGroupsUsersListRequest} adminGroupsUsersListRequest - admin groups list request
   * @returns {Observable<AdminUsersListResponse>}
   * @memberof AdminUsersService
   */
  public getAdminUsersList(adminGroupsUsersListRequest: AdminGroupsUsersListRequest): Observable<AdminUsersListResponse> {
    return this.httpService.post(Endpoint.CSP_USERS, adminGroupsUsersListRequest).pipe(
      map((response: GenericResponse) => deserialize(AdminUsersListResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Method to remove roles for a given admin user
   * @param {AdminUserListItem} adminUserListItem - admin users list request
   * @returns {Observable<boolean>}
   * @memberof AdminUsersService
   */
  public removeAdminUserRoles(adminUserListItem: AdminUserListItem): Observable<boolean> {
    return this.httpService
      .delete(Endpoint.ROLES_BY_CSP_USER_ID(adminUserListItem.user.directoryUserId), {
        body: new AdminUserListItem({
          platformRoles: adminUserListItem.platformRoles,
          serviceRoles: adminUserListItem.serviceRoles,
        }),
      })
      .pipe(
        map(() => true),
        catchError(requestErrorHandler),
      );
  }

  /**
   * Method to update roles for a given admin user
   * @param {AdminGroupsUsersListRequest} adminUserListItem - admin users list request
   * @returns {Observable<boolean>}
   * @memberof AdminUsersService
   */
  public updateAdminUserRoles(adminUserListItem: AdminUserListItem): Observable<boolean> {
    return this.httpService
      .put(
        Endpoint.ROLES_BY_CSP_USER_ID(adminUserListItem.user.directoryUserId),
        new AdminUserListItem({
          platformRoles: adminUserListItem.platformRoles,
          serviceRoles: adminUserListItem.serviceRoles,
        }),
      )
      .pipe(
        map(() => true),
        catchError(requestErrorHandler),
      );
  }

  /**
   * Method to update account migration status for invited admin user
   * @param {AccountMigrationStatusRequest} accountMigrationStatusRequest - account migration status request
   * @returns {Observable<boolean>}
   * @memberof AdminUsersService
   */
  public updateAccountMigrationStatus(accountMigrationStatusRequest: AccountMigrationStatusRequest): Observable<boolean> {
    return this.httpService
      .put(
        Endpoint.UPDATE_ACCOUNT_MIGRATION_STATUS(accountMigrationStatusRequest.accountId),
        new AccountMigrationStatusRequest({
          email: accountMigrationStatusRequest.email,
          cspMigrationStatus: accountMigrationStatusRequest.cspMigrationStatus,
        }),
      )
      .pipe(
        map(() => true),
        catchError(requestErrorHandler),
      );
  }
}
