/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { RolesState } from '@ws1c/admin-management/models/roles/roles-state.interface';
import {
  AccountsSearchResponse,
  GenericSearchRequest,
  LOAD_STATE,
  RolesSearchResponse,
  ScopeSetSearchResponse,
} from '@ws1c/intelligence-models';

/**
 * Defines initial state for Roles
 *
 * @export
 * @type {RolesState}
 */
export const initialRolesState: RolesState = {
  externalServiceRolesByIntegrationId: {},
  externalServiceRolesMap: {},
  serviceRolesLoadStateByIntegrationId: {},
  intelligenceRolesLoadingStatus: LOAD_STATE.NONE,
  intelligenceRolesResponse: new RolesSearchResponse(),
  intelligenceRolesRequest: new GenericSearchRequest(),
  isRolesConfirmModalOpen: false,
  orgGroupsByIntegrationId: {},
  orgGroupsLoadStateByIntegrationId: {},
  platformRoles: [],
  platformRolesLoadingState: LOAD_STATE.NONE,
  selectedIntelligenceRoles: [],
  selectedExternalServiceRolesMap: {},
  selectedOrgGroupByIntegrationId: {},
  selectedTenant: undefined,
  isDeactivateModalOpen: false,
  isCreateCustomRoleModalOpen: false,
  scopeSetAccessById: {},
  isSavingCustomRole: false,
  scopeSetSearchRequest: new GenericSearchRequest(),
  scopeSetSearchResponse: new ScopeSetSearchResponse(),
  isScopeSetLoading: false,
  isEditingCustomRolePermissions: false,
  isDeleteCustomRoleModalActive: false,
  isAccountsByRoleIdLoading: false,
  accountsByRoleIdRequest: new GenericSearchRequest(),
  accountsByRoleIdResponse: new AccountsSearchResponse(),
  selectedAccountsToUnassign: [],
  selectedUsersToAssign: [],
  bulkRoleIdsToDelete: [],
  roleToDelete: undefined,
  isUnassignAccountsModalActive: false,
  isAssignUsersModalActive: false,
  selectedRole: undefined,
  assignUsersListRequest: new GenericSearchRequest(),
};
