/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, requestErrorHandler } from '@dpa/ui-common';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  AdminGroupListItem,
  AdminGroupsInviteRequest,
  AdminGroupsListResponse,
  AdminGroupsSearchResponse,
  AdminGroupsUsersListRequest,
  AdminManagementPagedResponse,
} from '@ws1c/admin-management/models';
import { Endpoint, GenericResponse, HttpService } from '@ws1c/intelligence-common';

/**
 * Admin Groups Service
 *
 * @export
 * @class AdminGroupsService
 */
@Injectable()
export class AdminGroupsService {
  /**
   * Initializes instance of AdminGroupsService
   * @param {HttpService} httpService - Instance of HttpService
   * @memberof AdminGroupsService
   */
  constructor(private httpService: HttpService) {}

  /**
   * Method to retrieve admin groups list based on given request
   * @param {AdminGroupsUsersListRequest} adminGroupsUsersListRequest - admin groups list request
   * @returns {Observable<AdminGroupsListResponse>}
   * @memberof AdminGroupsService
   */
  public getAdminGroupsList(adminGroupsUsersListRequest: AdminGroupsUsersListRequest): Observable<AdminGroupsListResponse> {
    return this.httpService.post(Endpoint.CSP_GROUPS, adminGroupsUsersListRequest).pipe(
      map((response: GenericResponse) => deserialize(AdminGroupsListResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Method to search admin groups for a given search term
   * @param {string} searchTerm - Search term
   * @returns {Observable<AdminGroupsSearchResponse>} - Returns observable of AdminGroupsSearchResponse
   * @memberof AdminGroupsService
   */
  public searchAdminGroups(searchTerm: string): Observable<AdminGroupsSearchResponse> {
    return this.httpService.post(Endpoint.GROUPS_SEARCH(searchTerm), null).pipe(
      map((response: GenericResponse) => deserialize(AdminGroupsSearchResponse, response)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Method to invite admin groups with selected roles
   * @param {AdminGroupsInviteRequest} adminGroupsInviteRequest - Holds selected groups and roles list
   * @returns {Observable<boolean>}
   * @memberof AdminGroupsService
   */
  public inviteAdminGroups(adminGroupsInviteRequest: AdminGroupsInviteRequest): Observable<boolean> {
    return this.httpService.post(Endpoint.GROUPS_INVITATIONS, adminGroupsInviteRequest).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Method to remove roles for a given admin group
   * @param {AdminUserListItem} adminGroupListItem - admin groups list item
   * @returns {Observable<boolean>}
   * @memberof AdminGroupsService
   */
  public removeAdminGroupRoles(adminGroupListItem: AdminGroupListItem): Observable<boolean> {
    return this.httpService
      .delete(Endpoint.ROLES_BY_CSP_GROUP_ID(adminGroupListItem.directoryGroupId), {
        body: new AdminGroupListItem({
          platformRoles: adminGroupListItem.platformRoles,
          serviceRoles: adminGroupListItem.serviceRoles,
        }),
      })
      .pipe(
        map(() => true),
        catchError(requestErrorHandler),
      );
  }

  /**
   * Method to update roles for a given admin group
   * @param {AdminGroupListItem} adminGroupListItem - admin groups list item
   * @returns {Observable<boolean>}
   * @memberof AdminGroupsService
   */
  public updateAdminGroupRoles(adminGroupListItem: AdminGroupListItem): Observable<boolean> {
    return this.httpService
      .put(
        Endpoint.ROLES_BY_CSP_GROUP_ID(adminGroupListItem.directoryGroupId),
        new AdminGroupListItem({
          platformRoles: adminGroupListItem.platformRoles,
          serviceRoles: adminGroupListItem.serviceRoles,
        }),
      )
      .pipe(
        map(() => true),
        catchError(requestErrorHandler),
      );
  }

  /**
   * Method to retrieve users list for a given group
   * @param {AdminGroupsUsersListRequest} adminGroupsUsersListRequest - users in group list request
   * @param {string} id - directory group id
   * @returns {Observable<AdminManagementPagedResponse>}
   * @memberof AdminGroupsService
   */
  public getUsersListForGroup(
    adminGroupsUsersListRequest: AdminGroupsUsersListRequest,
    id: string,
  ): Observable<AdminManagementPagedResponse> {
    return this.httpService.post(Endpoint.USERS_FOR_GROUP(id), adminGroupsUsersListRequest).pipe(
      map((response: GenericResponse) => deserialize(AdminManagementPagedResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Method to retrieve nested groups for a given group
   * @param {AdminGroupsUsersListRequest} adminGroupsUsersListRequest - nested groups in group list request
   * @param {string} id - directory group id
   * @returns {Observable<AdminGroupsListResponse>}
   * @memberof AdminGroupsService
   */
  public getGroupsListForGroup(adminGroupsUsersListRequest: AdminGroupsUsersListRequest, id: string): Observable<AdminGroupsListResponse> {
    return this.httpService.post(Endpoint.NESTED_GROUPS_FOR_GROUP(id), adminGroupsUsersListRequest).pipe(
      map((response: GenericResponse) => deserialize(AdminGroupsListResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }
}
