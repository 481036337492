/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AdminUser } from './admin-user.model';

/**
 * Admin Users Search Response
 *
 * @export
 * @class AdminUsersSearchResponse
 */
@Serializable
export class AdminUsersSearchResponse {
  @JsonProperty({ name: 'data', cls: AdminUser, excludeToJson: true })
  public data: AdminUser[] = undefined;
}
