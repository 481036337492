/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

export const ADMIN_MANAGEMENT_ROUTE_NAMES = {
  HOME: 'settings/admin-management',
  ADMIN_GROUPS: 'settings/admin-management/admin-groups',
  ADMIN_GROUPS_ADD: 'settings/admin-management/admin-groups/add',
  ADMIN_GROUPS_EDIT: 'settings/admin-management/admin-groups/edit',
  ADMIN_GROUPS_LIST: 'settings/admin-management/admin-groups/list',
  ADMIN_ROLES: 'settings/admin-management/admin-roles',
  ADMIN_USERS: 'settings/admin-management/admin-users',
  ADMIN_USERS_ADD: 'settings/admin-management/admin-users/add',
  ADMIN_USERS_EDIT: 'settings/admin-management/admin-users/edit',
  ADMIN_USERS_LIST: 'settings/admin-management/admin-users/list',
  ADMINS_INVITATION: 'settings/admin-management/admins-invitation',
  ADMINS_INVITATION_LIST: 'settings/admin-management/admins-invitation/list',
  ADMINS_INVITATION_INVITE: 'settings/admin-management/admins-invitation/invite',
};
