/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { AdminUser } from './admin-users/admin-user.model';

/**
 * Admin Management Paged Response
 *
 * @export
 * @class AdminManagementPagedResponse
 * @implements {PagedResponse}
 */
@Serializable
export class AdminManagementPagedResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: AdminUser })
  public results: AdminUser[] = undefined;

  /**
   * Initializes instance of AdminManagementPagedResponse
   * @param {Array<Partial<AdminManagementPagedResponse>>} args - object initialization arguments
   * @memberof AdminManagementPagedResponse
   */
  constructor(...args: Array<Partial<AdminManagementPagedResponse>>) {
    Object.assign(this, ...args);
  }
}
