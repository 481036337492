/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { gql } from 'apollo-angular';

export const ROLES_FETCH = gql`
  query RoleViewDetailsPagedResponse($genericPagedRequestInput: GenericPagedRequestInput!) {
    retrievePagedRoleViews(input: $genericPagedRequestInput) {
      paged_response {
        offset
        page_size
        total_count
      }
      role_view_details {
        role_view_info {
          id
          name
          label
          description_text
          description
          service_type
          role_type
          scopes {
            id
            name
          }
          scope_sets {
            id
            name
            read_only
          }
          dap_compliant
          created_at
          modified_at
          created_by
          modified_by
        }
        assigned_accounts_count
      }
    }
  }
`;
