/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Admin User Invite
 *
 * @export
 * @class AdminUserInvite
 */
@Serializable
export class AdminUserInvite {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('email')
  public email: string = undefined;

  @JsonProperty('invitation_required')
  public isInvitationRequired: boolean = undefined;

  /**
   * Initializes instance of AdminUserInvite
   * @param {Array<Partial<AdminUserInvite>>} args - object initialization arguments
   * @memberof AdminUserInvite
   */
  constructor(...args: Array<Partial<AdminUserInvite>>) {
    return Object.assign(this, ...args);
  }
}
