/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { AdminGroupsListResponse, AdminGroupsState } from '@ws1c/admin-management/models/admin-groups';

import { AdminManagementPagedResponse } from '@ws1c/admin-management/models/admin-management-paged-response.model';

/**
 * Defines initial state for Admin Groups
 *
 * @export
 * @type {AdminGroupsState}
 */
export const initialAdminGroupsState: AdminGroupsState = {
  adminGroupsListResponse: new AdminGroupsListResponse(),
  editedAdminGroup: null,
  groupsListResponseForGroup: new AdminGroupsListResponse(),
  isInvitingAdminGroups: false,
  isLoadingAdminGroupsList: false,
  isLoadingGroupsListForGroup: false,
  isLoadingSearchResults: false,
  isLoadingUsersListForGroup: false,
  selectedAdminGroupId: null,
  searchedAdminGroups: [],
  selectedAdminGroupListItem: null,
  usersListResponseForGroup: new AdminManagementPagedResponse(),
};
