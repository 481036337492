/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { AdminGroupsService } from './admin-groups.service';
import { AdminUsersService } from './admin-users.service';
import { InvitedAdminsService } from './invited-admins.service';
import { RolesService } from './roles.service';

export const ADMIN_MANAGEMENT_SERVICES = [AdminGroupsService, AdminUsersService, InvitedAdminsService, RolesService];

export * from './admin-groups.service';
export * from './admin-users.service';
export * from './invited-admins.service';
export * from './roles.service';
