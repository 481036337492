/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models';
import { UserExistenceStatusType } from './user-existence-status-type.enum';

/**
 * User Existence Status
 *
 * @export
 * @class UserExistenceStatus
 */
@Serializable
export class UserExistenceStatus {
  @JsonProperty('username')
  public userName: string = undefined;

  @JsonProperty({ name: 'user_existence_status', customConverter: enumConverterFactory(UserExistenceStatusType) })
  public userExistenceStatus: UserExistenceStatusType = undefined;

  /**
   * Initializes instance of UserExistenceStatus
   * @param {Array<Partial<UserExistenceStatus>>} args - object initialization arguments
   * @memberof UserExistenceStatus
   */
  constructor(...args: Array<Partial<UserExistenceStatus>>) {
    Object.assign(this, ...args);
  }
}
