/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Admin Group Invite
 *
 * @export
 * @class AdminGroupInvite
 */
@Serializable
export class AdminGroupInvite {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('invitation_required')
  public isInvitationRequired: boolean = undefined;

  /**
   * Initializes instance of AdminGroupInvite
   * @param {Array<Partial<AdminGroupInvite>>} args - object initialization arguments
   * @memberof AdminGroupInvite
   */
  constructor(...args: Array<Partial<AdminGroupInvite>>) {
    return Object.assign(this, ...args);
  }
}
