/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * AdminRolesDetailTab
 * @export
 * @enum {number}
 */
export enum AdminRolesDetailTab {
  USERS,
  PERMISSIONS,
}
