/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import {
  AccountMigrationStatusRequest,
  AdminUser,
  AdminUserListItem,
  AdminUsersInviteRequest,
  AdminUsersListResponse,
  IntegrationOrgGroup,
} from '@ws1c/admin-management/models';
import { AccountRole } from '@ws1c/intelligence-models';

/**
 * Admin Users Actions
 *
 * @export
 * @class AdminUsersActions
 */
export class AdminUsersActions {
  public static readonly CATEGORY = '[ADMIN USERS]';

  public static readonly editAdminUser = createAction(
    `${AdminUsersActions.CATEGORY} EDIT_ADMIN`,
    props<{ adminUserListItem: AdminUserListItem }>(),
  );

  public static readonly deactivateAdmin = createAction(`${AdminUsersActions.CATEGORY} DEACTIVATE_ADMIN`);

  public static goToAdminUsersListPage = createAction(`${AdminUsersActions.CATEGORY} GO_TO_ADMIN_USERS_LIST_PAGE`);

  public static readonly inviteAdminUsers = createAction(
    `${AdminUsersActions.CATEGORY} INVITE_ADMIN_USERS`,
    props<{
      adminUsersInviteRequest: AdminUsersInviteRequest;
      hasExternalAdmins: boolean;
    }>(),
  );

  public static readonly inviteAdminUsersSuccess = createAction(`${AdminUsersActions.CATEGORY} INVITE_ADMIN_USERS_SUCCESS`);

  public static readonly inviteAdminUsersFailure = createAction(`${AdminUsersActions.CATEGORY} INVITE_ADMIN_USERS_FAILURE`);

  public static readonly searchAdminUsers = createAction(
    `${AdminUsersActions.CATEGORY} SEARCH_ADMIN_USERS`,
    props<{ searchTerm: string }>(),
  );

  public static readonly searchAdminUsersSuccess = createAction(
    `${AdminUsersActions.CATEGORY} SEARCH_ADMIN_USERS_SUCCESS`,
    props<{ searchedAdminUsers: AdminUser[] }>(),
  );

  public static readonly searchAdminUsersFailure = createAction(`${AdminUsersActions.CATEGORY} SEARCH_ADMIN_USERS_FAILURE`);

  public static readonly showLoadingAdminUsersList = createAction(`${AdminUsersActions.CATEGORY} SHOW_LOADING_ADMIN_USERS_LIST`);

  public static readonly loadAdminUsersList = createAction(
    `${AdminUsersActions.CATEGORY} LOAD_ADMIN_USERS_LIST`,
    props<{ pagedRequest: PagedRequest }>(),
  );

  public static readonly loadAdminUsersListSuccess = createAction(
    `${AdminUsersActions.CATEGORY} LOAD_ADMIN_USERS_LIST_SUCCESS`,
    props<{
      adminUsersListResponse: AdminUsersListResponse;
      orgGroupList: IntegrationOrgGroup[];
      serviceRoleById: Map<string, AccountRole>;
    }>(),
  );

  public static readonly loadAdminUsersListFailure = createAction(`${AdminUsersActions.CATEGORY} LOAD_ADMIN_USERS_LIST_FAILURE`);

  public static readonly removeAdminUserRoles = createAction(
    `${AdminUsersActions.CATEGORY} REMOVE_ADMIN_USER_ROLES`,
    props<{ adminUserListItem: AdminUserListItem }>(),
  );

  public static readonly setSelectedAdminUserListItem = createAction(
    `${AdminUsersActions.CATEGORY} SET_SELECTED_ADMIN_USER_LIST_ITEM`,
    props<{ selectedAdminUserListItem: AdminUserListItem }>(),
  );

  public static readonly updateAdminUserRoles = createAction(
    `${AdminUsersActions.CATEGORY} UPDATE_ADMIN_USER_ROLES`,
    props<{ adminUserListItem: AdminUserListItem }>(),
  );

  public static readonly updateAccountMigrationStatus = createAction(
    `${AdminUsersActions.CATEGORY} UPDATE_ACCOUNT_MIGRATION_STATUS`,
    props<{ accountMigrationStatusRequest: AccountMigrationStatusRequest }>(),
  );
}
