/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { SearchTerm } from '@ws1c/intelligence-models';

/**
 * Admin Groups Users List Request
 *
 * @export
 * @class AdminGroupsUsersListRequest
 */
@Serializable
export class AdminGroupsUsersListRequest {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty({ name: 'search_term', cls: SearchTerm })
  public searchTerm?: SearchTerm = undefined;

  /**
   * Initializes instance of AdminGroupsUsersListRequest
   * @param {Array<Partial<AdminGroupsUsersListRequest>>} args - object initialization arguments
   * @memberof AdminGroupsUsersListRequest
   */
  constructor(...args: Array<Partial<AdminGroupsUsersListRequest>>) {
    Object.assign(this, ...args);
  }
}
