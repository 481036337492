/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AdminRoles } from '@ws1c/admin-management/models/admin-roles.model';

/**
 * Admin Group List Item
 *
 * @export
 * @class AdminGroupListItem
 * @extends {AdminRoles}
 */
@Serializable
export class AdminGroupListItem extends AdminRoles {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('directory_group_id')
  public directoryGroupId: string = undefined;

  @JsonProperty('directory_id')
  public directoryId: string = undefined;

  @JsonProperty('display_name')
  public displayName: string = undefined;

  @JsonProperty('group_type')
  public groupType: string = undefined;

  @JsonProperty('users_count')
  public usersCount: number = undefined;

  /**
   * Initializes instance of AdminGroupListItem
   * @param {Array<Partial<AdminGroupListItem>>} args - object initialization arguments
   * @memberof AdminGroupListItem
   */
  constructor(...args: Array<Partial<AdminGroupListItem>>) {
    super();
    Object.assign(this, ...args);
  }
}
