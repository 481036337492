/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { PlatformRole, ServiceRole } from '@ws1c/admin-management/models/roles';

/**
 * AdminRoles Model
 *
 * @export
 * @abstract
 * @class AdminRoles
 */
@Serializable
export abstract class AdminRoles {
  @JsonProperty({ name: 'platform_roles', cls: PlatformRole })
  public platformRoles: PlatformRole[] = [];

  @JsonProperty({ name: 'service_roles', cls: ServiceRole })
  public serviceRoles: ServiceRole[] = [];

  /**
   * Creates an instance of AdminRoles
   * @param {Array<Partial<AdminRoles>>} args - Initialization arguments for AdminRoles
   * @memberof AdminRoles
   */
  constructor(...args: Array<Partial<AdminRoles>>) {
    Object.assign(this, ...args);
  }

  /**
   * Getter for hasPlatformRole
   * @type {boolean}
   * @memberof AdminRoles
   */
  public get hasPlatformRole(): boolean {
    return !!this.platformRoles[0];
  }

  /**
   * Getter for serviceNames
   * Returns set of services for which roles are assigned to pending invitation
   * @type {string[]}
   * @memberof AdminRoles
   */
  public get serviceNames(): string[] {
    return [...new Set(this.serviceRoles.map((serviceRole: ServiceRole) => serviceRole.service))];
  }
}
