/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Action, createReducer, on } from '@ngrx/store';

import { AdminManagementConfig } from '@ws1c/admin-management/const';
import { AdminUserListItem, AdminUsersState } from '@ws1c/admin-management/models/admin-users';
import { getListWithAssignedServiceRoles } from '@ws1c/admin-management/utils';
import { AdminUsersActions } from './admin-users.actions';
import { initialAdminUsersState } from './admin-users.state';

/**
 * _reducer
 * @type {ActionReducer<AdminUsersState, Action>}
 */
const _reducer = createReducer(
  initialAdminUsersState,

  on(
    AdminUsersActions.inviteAdminUsers,
    AdminUsersActions.updateAdminUserRoles,
    (state: AdminUsersState): AdminUsersState => ({
      ...state,
      isInvitingAdminUsers: true,
    }),
  ),

  on(
    AdminUsersActions.inviteAdminUsersSuccess,
    AdminUsersActions.inviteAdminUsersFailure,
    (state: AdminUsersState): AdminUsersState => ({
      ...state,
      isInvitingAdminUsers: false,
    }),
  ),

  on(
    AdminUsersActions.searchAdminUsers,
    (state: AdminUsersState, { searchTerm }: ReturnType<typeof AdminUsersActions.searchAdminUsers>): AdminUsersState => ({
      ...state,
      isLoadingSearchResults: (searchTerm || '').length >= AdminManagementConfig.MIN_SEARCH_TERM_LENGTH,
      searchedAdminUsers: searchTerm ? state.searchedAdminUsers : [],
    }),
  ),

  on(
    AdminUsersActions.searchAdminUsersSuccess,
    (state: AdminUsersState, { searchedAdminUsers }: ReturnType<typeof AdminUsersActions.searchAdminUsersSuccess>): AdminUsersState => ({
      ...state,
      isLoadingSearchResults: false,
      searchedAdminUsers,
    }),
  ),

  on(
    AdminUsersActions.searchAdminUsersFailure,
    (state: AdminUsersState): AdminUsersState => ({
      ...state,
      isLoadingSearchResults: false,
    }),
  ),

  on(
    AdminUsersActions.setSelectedAdminUserListItem,
    (
      state: AdminUsersState,
      { selectedAdminUserListItem }: ReturnType<typeof AdminUsersActions.setSelectedAdminUserListItem>,
    ): AdminUsersState => ({
      ...state,
      selectedAdminUserListItem,
    }),
  ),

  on(
    AdminUsersActions.showLoadingAdminUsersList,
    AdminUsersActions.loadAdminUsersList,
    (state: AdminUsersState): AdminUsersState => ({
      ...state,
      isLoadingAdminUsersList: true,
      selectedAdminUserListItem: null,
    }),
  ),

  on(
    AdminUsersActions.loadAdminUsersListSuccess,
    (
      state: AdminUsersState,
      { adminUsersListResponse, orgGroupList, serviceRoleById }: ReturnType<typeof AdminUsersActions.loadAdminUsersListSuccess>,
    ): AdminUsersState => {
      adminUsersListResponse.results = getListWithAssignedServiceRoles(
        adminUsersListResponse.results,
        orgGroupList,
        serviceRoleById,
      ) as AdminUserListItem[];
      return {
        ...state,
        adminUsersListResponse,
        isLoadingAdminUsersList: false,
      };
    },
  ),

  on(
    AdminUsersActions.loadAdminUsersListFailure,
    (state: AdminUsersState): AdminUsersState => ({
      ...state,
      isLoadingAdminUsersList: false,
    }),
  ),

  on(
    AdminUsersActions.removeAdminUserRoles,
    (state: AdminUsersState): AdminUsersState => ({
      ...state,
      isLoadingAdminUsersList: true,
    }),
  ),
);

/**
 * Creates and returns AdminUsersState
 * @export
 * @param {AdminUsersState} state - Holds state object of type AdminUsersState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {AdminUsersState}
 */
export function adminUsersReducer(state: AdminUsersState, action: Action): AdminUsersState {
  return _reducer(state, action);
}
