/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { PlatformRole, ServiceRole } from '@ws1c/admin-management/models/roles';
import { AdminUserInvite } from './admin-user-invite.model';

/**
 * Admin Users Invite Request
 *
 * @export
 * @class AdminUsersInviteRequest
 */
@Serializable
export class AdminUsersInviteRequest {
  @JsonProperty({ name: 'platform_roles', cls: PlatformRole })
  public platformRoles: PlatformRole[] = undefined;

  @JsonProperty({ name: 'service_roles', cls: ServiceRole })
  public serviceRoles: ServiceRole[] = undefined;

  @JsonProperty({ name: 'users', cls: AdminUserInvite })
  public users: AdminUserInvite[] = undefined;

  /**
   * Initializes instance of AdminUsersInviteRequest
   * @param {Array<Partial<AdminUsersInviteRequest>>} args - object initialization arguments
   * @memberof AdminUsersInviteRequest
   */
  constructor(...args: Array<Partial<AdminUsersInviteRequest>>) {
    Object.assign(this, ...args);
  }
}
