/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * User Existence Status Type
 *
 * @export
 * @enum {string}
 */
export enum UserExistenceStatusType {
  EXIST = 'EXIST',
  NON_EXIST = 'NON_EXIST',
  ORG_MEMBER = 'ORG_MEMBER',
}
