/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { PlatformRole, ServiceRole } from '@ws1c/admin-management/models/roles';
import { AdminGroupInvite } from './admin-group-invite.model';

/**
 * Admin Groups Invite Request
 *
 * @export
 * @class AdminGroupsInviteRequest
 */
@Serializable
export class AdminGroupsInviteRequest {
  @JsonProperty({ name: 'groups', cls: AdminGroupInvite })
  public groups: AdminGroupInvite[] = undefined;

  @JsonProperty({ name: 'platform_roles', cls: PlatformRole })
  public platformRoles: PlatformRole[] = undefined;

  @JsonProperty({ name: 'service_roles', cls: ServiceRole })
  public serviceRoles: ServiceRole[] = undefined;

  /**
   * Initializes instance of AdminGroupsInviteRequest
   * @param {Array<Partial<AdminGroupsInviteRequest>>} args - object initialization arguments
   * @memberof AdminGroupsInviteRequest
   */
  constructor(...args: Array<Partial<AdminGroupsInviteRequest>>) {
    Object.assign(this, ...args);
  }
}
