/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Action, createReducer, on } from '@ngrx/store';

import { PlatformRole, RolesState } from '@ws1c/admin-management/models';
import { AccountRole, COLUMN_NAMES, GenericSearchRequest, LOAD_STATE, RolesSearchResponse, SearchTerm } from '@ws1c/intelligence-models';
import { RolesActions } from './roles.actions';
import { initialRolesState } from './roles.state';

/**
 * _reducer
 * @type {ActionReducer<RolesState, Action>}
 */
const _reducer = createReducer(
  initialRolesState,

  on(
    RolesActions.getExternalServiceRoles,
    (state: RolesState, { externalServiceRolesRequest }: ReturnType<typeof RolesActions.getExternalServiceRoles>): RolesState => {
      const integrationId: string = externalServiceRolesRequest?.integrationDetails?.id;
      return {
        ...state,
        serviceRolesLoadStateByIntegrationId: {
          ...state.serviceRolesLoadStateByIntegrationId,
          [integrationId]: LOAD_STATE.IN_FLIGHT,
        },
      };
    },
  ),

  on(
    RolesActions.getExternalServiceRolesSuccess,
    (
      state: RolesState,
      { integrationId, externalServiceRolesResponse }: ReturnType<typeof RolesActions.getExternalServiceRolesSuccess>,
    ): RolesState => {
      const externalServiceRolesMap: Record<string, AccountRole> = { ...state.externalServiceRolesMap };
      externalServiceRolesResponse.results.forEach((role: AccountRole) => {
        if (!externalServiceRolesMap[role.roleId]) {
          externalServiceRolesMap[role.roleId] = role;
        }
      });
      return {
        ...state,
        // Set roles for service integration
        externalServiceRolesByIntegrationId: {
          ...state.externalServiceRolesByIntegrationId,
          [integrationId]: externalServiceRolesResponse,
        },
        // set external service roles map
        externalServiceRolesMap,
        // Set loading status for service integration
        serviceRolesLoadStateByIntegrationId: {
          ...state.serviceRolesLoadStateByIntegrationId,
          [integrationId]: LOAD_STATE.SUCCESS,
        },
      };
    },
  ),

  on(
    RolesActions.getExternalServiceRolesFailure,
    (state: RolesState, { integrationId }: ReturnType<typeof RolesActions.getExternalServiceRolesFailure>): RolesState => ({
      ...state,
      serviceRolesLoadStateByIntegrationId: {
        ...state.serviceRolesLoadStateByIntegrationId,
        [integrationId]: LOAD_STATE.FAILURE,
      },
    }),
  ),

  on(
    RolesActions.getAllRolesForExternalService,
    (state: RolesState, { externalServiceRolesRequest }: ReturnType<typeof RolesActions.getAllRolesForExternalService>): RolesState => {
      const integrationId: string = externalServiceRolesRequest?.integrationDetails?.id;
      return {
        ...state,
        serviceRolesLoadStateByIntegrationId: {
          ...state.serviceRolesLoadStateByIntegrationId,
          [integrationId]: LOAD_STATE.IN_FLIGHT,
        },
      };
    },
  ),

  on(
    RolesActions.getIntelligenceRoles,
    (state: RolesState): RolesState => ({
      ...state,
      intelligenceRolesLoadingStatus: state.intelligenceRolesResponse?.results?.length
        ? state.intelligenceRolesLoadingStatus
        : LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    RolesActions.getIntelligenceRolesSuccess,
    (state: RolesState, { intelligenceRolesResponse }: ReturnType<typeof RolesActions.getIntelligenceRolesSuccess>): RolesState => ({
      ...state,
      intelligenceRolesResponse,
      intelligenceRolesLoadingStatus: LOAD_STATE.SUCCESS,
    }),
  ),

  on(
    RolesActions.getIntelligenceRolesFailure,
    (state: RolesState): RolesState => ({
      ...state,
      intelligenceRolesLoadingStatus: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    RolesActions.getPlatformRoles,
    (state: RolesState): RolesState => ({
      ...state,
      platformRolesLoadingState: LOAD_STATE.IN_FLIGHT,
    }),
  ),

  on(
    RolesActions.getPlatformRolesSuccess,
    (state: RolesState, { platformRoles }: ReturnType<typeof RolesActions.getPlatformRolesSuccess>): RolesState => ({
      ...state,
      platformRoles,
      platformRolesLoadingState: LOAD_STATE.SUCCESS,
    }),
  ),

  on(
    RolesActions.getPlatformRolesFailure,
    (state: RolesState): RolesState => ({
      ...state,
      platformRolesLoadingState: LOAD_STATE.FAILURE,
    }),
  ),

  on(
    RolesActions.resetSelectedRoles,
    (state: RolesState): RolesState => ({
      ...state,
      platformRoles: state.platformRoles.map((platformRole: PlatformRole) => {
        return new PlatformRole({
          ...platformRole,
          isSelected: false,
        });
      }),
      selectedExternalServiceRolesMap: {},
      selectedIntelligenceRoles: [],
      selectedOrgGroupByIntegrationId: {},
    }),
  ),

  on(
    RolesActions.setRolesConfirmModalOpenState,
    (state: RolesState, { isOpen }: ReturnType<typeof RolesActions.setRolesConfirmModalOpenState>): RolesState => ({
      ...state,
      isRolesConfirmModalOpen: isOpen,
    }),
  ),

  on(
    RolesActions.setSelectedExternalServiceRoles,
    (
      state: RolesState,
      { integrationId, tenantUuid, selectedExternalServiceRoles }: ReturnType<typeof RolesActions.setSelectedExternalServiceRoles>,
    ): RolesState => {
      // get existing selected roles from state
      const currentSelectedRoles: AccountRole[] = state.selectedExternalServiceRolesMap?.[integrationId]?.[tenantUuid] || [];
      // get the new roles list shown in grid
      const updatedRolesList: RolesSearchResponse = state.externalServiceRolesByIntegrationId[integrationId];
      const rolesToAppend: AccountRole[] = [];
      currentSelectedRoles.forEach((selectedRole: AccountRole) => {
        const selectedRoleExistsInList: AccountRole = updatedRolesList.results.find(
          (role: AccountRole) => role.roleId === selectedRole.roleId,
        );
        // if a peviously selected role is no more listed in the grid, due to page size change or navigation to next page,
        // store it in rolesToAppend and append it with selectedExternalServiceRoles(conatins selection on current listed roles)
        // when storing in state later
        if (!selectedRoleExistsInList) {
          rolesToAppend.push(selectedRole);
        }
      });
      return {
        ...state,
        selectedExternalServiceRolesMap: {
          ...state.selectedExternalServiceRolesMap,
          [integrationId]: {
            ...state.selectedExternalServiceRolesMap[integrationId],
            [tenantUuid]: [...selectedExternalServiceRoles, ...rolesToAppend],
          },
        },
      };
    },
  ),

  on(
    RolesActions.setSelectedIntelligenceRoles,
    (state: RolesState, { selectedIntelligenceRoles }: ReturnType<typeof RolesActions.setSelectedIntelligenceRoles>): RolesState => ({
      ...state,
      selectedIntelligenceRoles,
    }),
  ),

  on(
    RolesActions.getOrgGroupListByIntegrationId,
    (state: RolesState, { integrationId }: ReturnType<typeof RolesActions.getOrgGroupListByIntegrationId>): RolesState => ({
      ...state,
      orgGroupsLoadStateByIntegrationId: {
        ...state.orgGroupsLoadStateByIntegrationId,
        [integrationId]: true,
      },
      serviceRolesLoadStateByIntegrationId: {
        ...state.serviceRolesLoadStateByIntegrationId,
        [integrationId]: LOAD_STATE.IN_FLIGHT,
      },
    }),
  ),

  on(
    RolesActions.getOrgGroupListByIntegrationIdSuccess,
    (
      state: RolesState,
      { integrationId, orgGroupList }: ReturnType<typeof RolesActions.getOrgGroupListByIntegrationIdSuccess>,
    ): RolesState => {
      return {
        ...state,
        orgGroupsByIntegrationId: {
          ...state.orgGroupsByIntegrationId,
          [integrationId]: [...orgGroupList],
        },
        orgGroupsLoadStateByIntegrationId: {
          ...state.orgGroupsLoadStateByIntegrationId,
          [integrationId]: false,
        },
      };
    },
  ),

  on(
    RolesActions.getOrgGroupListByIntegrationIdFailure,
    (state: RolesState, { integrationId }: ReturnType<typeof RolesActions.getOrgGroupListByIntegrationIdFailure>): RolesState => ({
      ...state,
      orgGroupsLoadStateByIntegrationId: {
        ...state.orgGroupsLoadStateByIntegrationId,
        [integrationId]: false,
      },
    }),
  ),

  on(
    RolesActions.setSelectedOrgGroupByIntegrationId,
    (
      state: RolesState,
      { integrationId, selectedOrgGroup }: ReturnType<typeof RolesActions.setSelectedOrgGroupByIntegrationId>,
    ): RolesState => {
      return {
        ...state,
        selectedOrgGroupByIntegrationId: {
          ...state.selectedOrgGroupByIntegrationId,
          [integrationId]: selectedOrgGroup,
        },
      };
    },
  ),

  on(
    RolesActions.setSelectedTenant,
    (state: RolesState, { selectedTenant }: ReturnType<typeof RolesActions.setSelectedTenant>): RolesState => {
      return {
        ...state,
        selectedTenant,
      };
    },
  ),

  on(
    RolesActions.setSelectedRolesForAllExternalServices,
    (
      state: RolesState,
      { selectedExternalServiceRolesMap }: ReturnType<typeof RolesActions.setSelectedRolesForAllExternalServices>,
    ): RolesState => ({
      ...state,
      selectedExternalServiceRolesMap,
    }),
  ),

  on(
    RolesActions.setDeactivateModalOpenState,
    (state: RolesState, { isOpen }: ReturnType<typeof RolesActions.setDeactivateModalOpenState>): RolesState => ({
      ...state,
      isDeactivateModalOpen: isOpen,
    }),
  ),

  on(
    RolesActions.openCreateCustomRoleModal,
    (state: RolesState): RolesState => ({
      ...state,
      isCreateCustomRoleModalOpen: true,
      isEditingCustomRolePermissions: false,
    }),
  ),

  on(
    RolesActions.closeCreateCustomRoleModal,
    (state: RolesState): RolesState => ({
      ...state,
      isCreateCustomRoleModalOpen: false,
      isEditingCustomRolePermissions: false,
      scopeSetAccessById: {},
    }),
  ),

  on(
    RolesActions.setSelectedRole,
    (state: RolesState, { role }: ReturnType<typeof RolesActions.setSelectedRole>): RolesState => ({
      ...state,
      selectedRole: role,
    }),
  ),

  on(
    RolesActions.getScopeSet,
    (state: RolesState): RolesState => ({
      ...state,
      isScopeSetLoading: true,
    }),
  ),

  on(
    RolesActions.getScopeSetSuccess,
    (state: RolesState, { response }: ReturnType<typeof RolesActions.getScopeSetSuccess>): RolesState => ({
      ...state,
      scopeSetSearchResponse: response,
      isScopeSetLoading: false,
    }),
  ),

  on(
    RolesActions.getScopeSetFailure,
    (state: RolesState): RolesState => ({
      ...state,
      isScopeSetLoading: false,
    }),
  ),

  on(
    RolesActions.setScopeSetAccess,
    (state: RolesState, { scopeSetId, scopeSetAccessOption }: ReturnType<typeof RolesActions.setScopeSetAccess>): RolesState => ({
      ...state,
      scopeSetAccessById: {
        ...state.scopeSetAccessById,
        [scopeSetId]: scopeSetAccessOption,
      },
    }),
  ),

  on(
    RolesActions.setScopeSetAccessById,
    (state: RolesState, { scopeSetAccessById }: ReturnType<typeof RolesActions.setScopeSetAccessById>): RolesState => ({
      ...state,
      scopeSetAccessById,
    }),
  ),

  on(
    RolesActions.saveCustomRole,
    (state: RolesState): RolesState => ({
      ...state,
      isSavingCustomRole: true,
    }),
  ),

  on(
    RolesActions.saveCustomRoleSuccess,
    (state: RolesState): RolesState => ({
      ...state,
      isCreateCustomRoleModalOpen: false,
      scopeSetAccessById: {},
      isSavingCustomRole: false,
    }),
  ),

  on(
    RolesActions.saveCustomRoleFailure,
    (state: RolesState): RolesState => ({
      ...state,
      isSavingCustomRole: false,
    }),
  ),

  on(
    RolesActions.editCustomRole,
    (state: RolesState): RolesState => ({
      ...state,
      isEditingCustomRolePermissions: true,
      isCreateCustomRoleModalOpen: true,
    }),
  ),

  on(
    RolesActions.saveEditCustomRoleSuccess,
    (state: RolesState): RolesState => ({
      ...state,
      isEditingCustomRolePermissions: false,
      isCreateCustomRoleModalOpen: false,
      scopeSetAccessById: {},
    }),
  ),

  on(
    RolesActions.deleteCustomRole,
    (state: RolesState, { role }: ReturnType<typeof RolesActions.deleteCustomRole>): RolesState => ({
      ...state,
      isDeleteCustomRoleModalActive: true,
      bulkRoleIdsToDelete: [role.roleId],
      roleToDelete: role,
    }),
  ),

  on(
    RolesActions.deleteCustomRoleSuccess,
    RolesActions.cancelDeleteCustomRole,
    (state: RolesState): RolesState => ({
      ...state,
      isDeleteCustomRoleModalActive: false,
      bulkRoleIdsToDelete: [],
      roleToDelete: undefined,
    }),
  ),

  on(
    RolesActions.getAccountsByRoleId,
    (state: RolesState): RolesState => ({
      ...state,
      isAccountsByRoleIdLoading: true,
    }),
  ),

  on(
    RolesActions.getAccountsByRoleIdSuccess,
    (state: RolesState, { accountsSearchResponse }: ReturnType<typeof RolesActions.getAccountsByRoleIdSuccess>): RolesState => ({
      ...state,
      isAccountsByRoleIdLoading: false,
      accountsByRoleIdResponse: accountsSearchResponse,
    }),
  ),

  on(
    RolesActions.getAccountsByRoleIdFailure,
    (state: RolesState): RolesState => ({
      ...state,
      isAccountsByRoleIdLoading: false,
    }),
  ),

  on(
    RolesActions.selectAccountsToUnassign,
    (state: RolesState, { accounts }: ReturnType<typeof RolesActions.selectAccountsToUnassign>): RolesState => ({
      ...state,
      selectedAccountsToUnassign: accounts,
    }),
  ),

  on(
    RolesActions.selectUsersToAssign,
    (state: RolesState, { accounts }: ReturnType<typeof RolesActions.selectUsersToAssign>): RolesState => ({
      ...state,
      selectedUsersToAssign: accounts,
    }),
  ),

  on(
    RolesActions.unassignAccounts,
    (state: RolesState): RolesState => ({
      ...state,
      isUnassignAccountsModalActive: true,
    }),
  ),

  on(
    RolesActions.cancelUnassignAccounts,
    RolesActions.unassignAccountsFailure,
    (state: RolesState): RolesState => ({
      ...state,
      isUnassignAccountsModalActive: false,
    }),
  ),

  on(
    RolesActions.unassignAccountsSuccess,
    (state: RolesState): RolesState => ({
      ...state,
      isUnassignAccountsModalActive: false,
      selectedAccountsToUnassign: [],
    }),
  ),

  on(
    RolesActions.assignUsers,
    (state: RolesState): RolesState => ({
      ...state,
      isAssignUsersModalActive: true,
    }),
  ),

  on(
    RolesActions.cancelAssignUsers,
    RolesActions.confirmAssignUsers,
    (state: RolesState): RolesState => ({
      ...state,
      isAssignUsersModalActive: false,
    }),
  ),

  on(
    RolesActions.changeUsersPagination,
    (state: RolesState, { pagedRequest }: ReturnType<typeof RolesActions.changeUsersPagination>): RolesState => ({
      ...state,
      accountsByRoleIdRequest: pagedRequest,
      isAccountsByRoleIdLoading: true,
    }),
  ),

  on(
    RolesActions.changeRolesPagination,
    (state: RolesState, { pagedRequest }: ReturnType<typeof RolesActions.changeRolesPagination>): RolesState => ({
      ...state,
      intelligenceRolesRequest: new GenericSearchRequest({
        ...state.intelligenceRolesRequest,
        from: pagedRequest.from,
        size: pagedRequest.size,
      }),
    }),
  ),

  on(
    RolesActions.setAssignUsersListRequest,
    (state: RolesState, { searchRequest }: ReturnType<typeof RolesActions.setAssignUsersListRequest>): RolesState => ({
      ...state,
      assignUsersListRequest: searchRequest,
    }),
  ),

  on(
    RolesActions.setAccountSearchTerm,
    (state: RolesState, { query }: ReturnType<typeof RolesActions.setAccountSearchTerm>): RolesState => ({
      ...state,
      assignUsersListRequest: new GenericSearchRequest({
        ...state.assignUsersListRequest,
        searchTerms: [
          new SearchTerm({
            value: query,
            fields: [COLUMN_NAMES.byName.first_name, COLUMN_NAMES.byName.last_name, COLUMN_NAMES.byName.email],
          }),
        ],
      }),
    }),
  ),
);

/**
 * Creates and returns RolesState
 * @export
 * @param {RolesState} state - Holds state object of type RolesState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {RolesState}
 */
export function rolesReducer(state: RolesState, action: Action): RolesState {
  return _reducer(state, action);
}
