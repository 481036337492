/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Integration Org Group
 *
 * @export
 * @class IntegrationOrgGroup
 */
@Serializable
export class IntegrationOrgGroup {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('tenant_uuid')
  public tenantUuid: string = undefined;

  @JsonProperty('display_name')
  public displayName: string = undefined;

  /**
   * Creates an instance of IntegrationOrgGroup
   * @param {Array<Partial<IntegrationOrgGroup>>} args - Initialization arguments for IntegrationOrgGroup
   * @memberof IntegrationOrgGroup
   */
  constructor(...args: Array<Partial<IntegrationOrgGroup>>) {
    Object.assign(this, ...args);
  }
}
