/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { AdminsInvitationState, EmailLookupDetails } from '@ws1c/admin-management/models/admins-invitation';

/**
 * Defines initial state for Admins Invitation
 *
 * @export
 * @type {AdminsInvitationState}
 */
export const initialAdminsInvitationState: AdminsInvitationState = {
  adminsEmailLookupDetailsMap: new Map<string, EmailLookupDetails>(),
  isLoadingPendingInvitationsList: false,
  isNotifyingInvitedAdmins: false,
  isRevokingAdminInvitations: false,
  pendingInvitationsList: [],
  selectedAccountId: undefined,
  selectedPendingInvitations: [],
};
