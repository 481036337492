/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { AdminUserListItem } from './admin-user-list-item.model';

/**
 * AdminUsersList Response
 *
 * @export
 * @class AdminUsersListResponse
 */
@Serializable
export class AdminUsersListResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: AdminUserListItem })
  public results: AdminUserListItem[] = undefined;

  /**
   * Initializes instance of AdminUsersListResponse
   * @param {Array<Partial<AdminUsersListResponse>>} args - object initialization arguments
   * @memberof AdminUsersListResponse
   */
  constructor(...args: Array<Partial<AdminUsersListResponse>>) {
    Object.assign(this, ...args);
  }
}
