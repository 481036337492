/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { LOAD_STATE } from '@ws1c/intelligence-models';

/**
 * Email Lookup Details
 *
 * @export
 * @class EmailLookupDetails
 */
export class EmailLookupDetails {
  public email: string;

  public status: LOAD_STATE;

  public isAllowed: boolean;

  public isUserExistenceStatusNonExist: boolean;

  /**
   * Initializes instance of EmailLookupDetails
   * @param {Array<Partial<EmailLookupDetails>>} args - object initialization arguments
   * @memberof EmailLookupDetails
   */
  constructor(...args: Array<Partial<EmailLookupDetails>>) {
    Object.assign(this, ...args);
  }
}
